<template>
  <iframe :src="link" width="2560px" height="1440px" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
</template>
<script>
export default {
  computed:{
    link(){
      return localStorage.getItem("LINK")
    }
  }
}
</script>