<template>
  <div class="date-time">
    <div class="time R">{{ dateTime.time }}</div>
    <div class="date">{{ dateTime.date }}</div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "DateTime",
  data() {
    return {
      dateTime: {
        date: "",
        time: "",
      },
    };
  },
  mounted() {
    this.formatDate();
    setInterval(this.formatDate, 1000);
  },
  methods: {
    formatDate() {
      let dateTime = dayjs();
      this.dateTime = {
        date: dateTime.format("YYYY年MM月DD日"),
        time: dateTime.format("HH:mm"),
        // time: dateTime.format("HH:mm:ss"),
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.formatDate);
  },
};
</script>

<style lang="scss" scoped>
.date-time {
  color: #fff;
  line-height: 1;
  .time{
    font-size: 120px;
    margin-bottom: 10px;
  }
  .date{
    font-weight: bold;
    font-size: 30px;
  }
}
</style>
