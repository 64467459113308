import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import * as helper from './assets/js/helper'
import './plugins/element.js'
import 'hls-player/dist/hls-player.css'
import hlsPlayer from 'hls-player'
Vue.use(hlsPlayer)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import Swiper, { Autoplay } from "swiper";
import 'swiper/swiper-bundle.css'
// import 'swiper/swiper.min.css'
Swiper.use([Autoplay]);
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$at = helper

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
