<template>
  <div class="distribution">
    <div class="page-left scroll-y-true-wrap">
    <div class="scroll-y-true">
      <el-collapse class="collapse-content-list" v-model="activeName" accordion>
        <el-collapse-item v-for="(item, index) in list" :key="index" class="collapse-content-item" :title="item.title" :name="item.id">
          <div v-for="(subItem, subIndex) in item.industries" :key="subIndex" @click="read(subItem.id)" class="collapse-content" :class="subItem.id == id ? 'active-collapse-content' : '' ">{{subItem.title}}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    </div>
    <div class="page-middle">
      <DashboardMap :map="map" />
    </div>
    <div class="distribution-right-content-wrap" v-if="$at.notEmpty(info)">
      <div class="distribution-right-content-title">{{info.title}}</div>
      <div class="distribution-right-content-info" v-html="info.content"></div>
      <div v-if="info.link" class="distribution-link" @click="jump(info.link)">公司官网</div>
    </div>
    <FsLightbox
      :toggler="toggler"
      :sources="[CustomSource]"
    />
  </div>
</template>

<script>
import DashboardMap from "@/components/Map/EchartsMap.vue";
import FsLightbox from "fslightbox-vue";
import CustomSource from "@/components/CustomSource.vue"
export default {
  name: "Distribution",
  components: {
    DashboardMap,
    FsLightbox
  },
  data() {
    return {
      toggler: false,
      CustomSource: CustomSource,
      map: [],
      activeName: '',
      companyInfo: {},
      list: [],
      id: 0,
      info: {}
    };
  },
  mounted() {
    this.loadList()
    this.loadMap()
  },
  methods: {
    jump(link){
      this.toggler = !this.toggler
      localStorage.setItem("LINK", link)
    },
    loadList(){
      let _self = this
      _self.$api.postCategory({
        withItem: 1
      })
      .then((res) => {
        if(_self.$at.notEmpty(res.data)){
          _self.activeName = res.data[0].id
          _self.list = res.data
          if(_self.$at.notEmpty(res.data[0].industries)){
            _self.id = res.data[0].industries[0].id
            _self.loadData(_self.id)
          }
          // let map = []
          // for (let i = 0; i < res.data.length; i++) {
          //   map = [...map, ...res.data[i].industries]
          // }
          // this.map = map
        }
      })
      .catch(() => {});
    },
    loadMap(){
       let _self = this
      _self.$api.getIndustriesMap({
        limit: 999
      })
      .then((res) => {
        if(_self.$at.notEmpty(res.data)){
          this.map = res.data
        }
      })
      .catch(() => {});
    },
    loadData(id){
      let _self = this
      _self.$api.postDetail({
        id: id
      })
      .then((res) => {
        _self.info = res.data
      })
      .catch(() => {});
    },
    read(id){
      this.id = id
      this.loadData(id)
    },
    getNotice(params){
      this.companyInfo = params
    }
  }
};
</script>
<style lang="scss">
.distribution .page-left{
  width: 1440px;
  padding: 320px 200px 50px;
}
.scroll-y-true-wrap{
  display: flex;
}
.scroll-y-true{
  overflow: auto;
  width: 100%;
  height: 100%;
}
.scroll-y-true::-webkit-scrollbar{
  border-width:1px;
}
.collapse-content-list.el-collapse{
  border: 0;
}
.collapse-content-list .el-collapse-item__header,
.collapse-content-list .el-collapse-item__wrap{
  border-color: rgba(112, 112, 112, 0.2);
  background-color: transparent;
}
.collapse-content-list .el-collapse-item__header{
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  height: auto;
  line-height: 1;
  padding: 25px 0;
  margin-bottom: 5px;
}
.collapse-content{
  cursor: pointer;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
}
.active-collapse-content{
  color: #4AC48F;
}
.distribution-right-content-wrap{
  position: absolute;
  top: 290px;
  right: 220px;
  width: 1100px;
  padding: 70px 90px;
  background-color: #FFFFFF;
  .distribution-right-content-title{
    font-size: 60px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 40px;
  }
  .distribution-right-content-img{
    margin-bottom: 70px;
  }
  .distribution-right-content-info{
    font-size: 36px;
    color: #707070;
    line-height: 1.6;
    p{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.distribution-link{
  cursor: pointer;
  margin-top: 20px;
  font-size: 40px;
  color: #6399FB;
  text-decoration: underline
}
.fslightbox-toolbar{
  display: none !important;
}
</style>