<template>
  <div class="login">
    <div class="login-form-wrap">
      <div class="login-form-logo"><img src="@/assets/images/logo/logo.png" alt=""></div>
      <div class="login-form">
        <h1 class="login-form-title">登录页面</h1>
        <el-form ref="loginForm" :model="form" :rules="rules">
          <el-form-item prop="username" class="globaliInput">
            <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password" class="globaliInput">
            <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="global-btn" type="success" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { CHANGE_IS_LOGIN_PAGE } from "@/store/mutation-types";
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.$api.login(this.form).then((res) => {
            if (res.status == "error") {
              this.$message.error(res.message);
            }
            if (res.status == "success") {
              localStorage.setItem("TOKEN", "bearer " + res.data.token);
              this.$router.replace("/");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  beforeRouteEnter(_to, _from, next) {
    store.commit(CHANGE_IS_LOGIN_PAGE, true);
    next();
  },
  beforeRouteLeave(_to, _from, next) {
    store.commit(CHANGE_IS_LOGIN_PAGE, false);
    next();
  },
};
</script>
<style lang="scss">
  .login{
    padding: 500px 0;
  }
  .login-form-wrap{
    margin: 0 auto;
    width: 1200px;
  }
  .login-form-logo{
    line-height: 0;
    margin-bottom: 100px;
    text-align: center;
    img{
      max-height: 160px;
    }
  }
  .login-form{
    text-align: center;
    background-color: #fff;
    padding: 100px;
    .login-form-title{
      color: #000000;
      font-size: 60px;
      margin: 0 0 60px;
    }
  }
  .login-form .globaliInput{
    margin-bottom: 60px;
  }
  .login-form .globaliInput .el-input__inner{
    height: 90px;
    line-height: 90px;
    border-radius: 0;
    padding: 0 30px;
    font-size: 30px;
    font-weight: bold;
    color: #707070;
    text-align: center;
    border: 2px solid #000000;
  }
  .login-form .globaliInput .el-form-item__error {
    font-size: 20px;
    padding-top: 10px;
  }
  .login-form .global-btn.el-button.el-button--success{
    width: 500px;
    justify-content: center;
    margin: 0 auto;
  }
</style>
