<template>
  <div class="wrapper">
    <header class="header">
      <div class="header-left"><img src="@/assets/images/logo/logo.png" alt=""></div>
      <div class="header-nav"><NavMenu /></div>
      <div class="header-right"><DateTime /></div>
    </header>
    <div class="main-content">
      <transition>
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";
import DateTime from "@/components/DateTime.vue";
export default {
  name: "main-layout",
  components: {
    NavMenu,
    DateTime,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.header{
  padding: 50px 100px 0;
  display: flex;
  z-index: 1;
  position: relative;
  &-left{
    margin-top: 50px;
    width: 600px;
    min-width: 600px;
    line-height: 0;
    img{
      max-height: 160px;
    }
  }
  &-nav{
    flex: 1;
    display: flex;
    justify-content: center;
  }
  &-right{
    width: 330px;
    min-width: 330px;
    text-align: center;
  }
}
</style>
