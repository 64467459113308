<template>
  <div class="detail">
    <div class="btn-wrap">
      <el-button class="global-btn back-btn" type="success" @click="back"
        >返回列表</el-button
      >
      <el-button class="global-btn back-btn" type="success" v-if="preID" @click="pre"
        >上一个</el-button
      >
      <el-button class="global-btn back-btn" type="success" v-if="nextID" @click="next"
        >下一个</el-button
      >
    </div>
    <div class="equipment-detail-content">
      <div class="equipment-detail-left">
        <div class="equipment-detail-title">
          {{ $at.notEmpty(detail.city) ? detail.city : "--" }}
          {{ $at.notEmpty(detail.title) ? detail.title : "--" }} 设备信息
        </div>
        <div class="equipment-detail-info">
          <div class="equipment-detail-info-left">
            <div class="equipment-control">
              <div class="equipment-control-item">
                <div class="equipment-control-left">预热</div>
                <div class="equipment-control-middle"></div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_ksyr) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">顶风开度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_dfkql)
                      ? detail.data.f_dfkql
                      : "--"
                  }}%
                </div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_dbff) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">侧风开度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_cfkql)
                      ? detail.data.f_cfkql
                      : "--"
                  }}%
                </div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_cbff) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">二燃风开度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_erfkql)
                      ? detail.data.f_erfkql
                      : "--"
                  }}%
                </div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_erff) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">主燃功率</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_zrhydx)
                      ? detail.data.f_zrhydx
                      : "--"
                  }}%
                </div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_zrzt) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">二燃功率</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_erhydx)
                      ? detail.data.f_erhydx
                      : "--"
                  }}%
                </div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_erzt) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">供氧风机运行频率</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_gyfjyxpl)
                      ? detail.data.f_gyfjyxpl
                      : "--"
                  }}Hz
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">射流风机运行频率</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_slfjyxpl)
                      ? detail.data.f_slfjyxpl
                      : "--"
                  }}Hz
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">引风风机运行频率</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_yfjyxpl)
                      ? detail.data.f_yfjyxpl
                      : "--"
                  }}Hz
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">含氧量</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ydhyl)
                      ? detail.data.f_ydhyl
                      : "--"
                  }}vol%
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">炉内压力</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_dnyl)
                      ? detail.data.f_dnyl
                      : "--"
                  }}Pa
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">脱硫塔PH值</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_tltph)
                      ? detail.data.f_tltph
                      : "--"
                  }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">脱硫塔压差</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_tltyc)
                      ? detail.data.f_tltyc
                      : "--"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="equipment-detail-info-middle">
            <div class="equipment-control">
              <div class="equipment-control-item">
                <div class="equipment-control-left">油泵</div>
                <div class="equipment-control-middle"></div>
                <div class="equipment-control-right">
                  {{ computedStatus(detail.data.f_ybzt) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">主燃实际温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_zrscswd)
                      ? detail.data.f_zrscswd
                      : "--"
                  }}℃
                </div>
                <div class="equipment-control-right">
                  {{ computedFireStatus(detail.data.f_zrsdh) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">主燃设定温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_zrsszwd)
                      ? detail.data.f_zrsszwd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">二燃实际温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ecrscswd)
                      ? detail.data.f_ecrscswd
                      : "--"
                  }}℃
                </div>
                <div class="equipment-control-right">
                  {{ computedFireStatus(detail.data.f_ecrsdh) }}
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">二燃设定温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ecrsszwd)
                      ? detail.data.f_ecrsszwd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">二燃出口烟道温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ydcswd)
                      ? detail.data.f_ydcswd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">换热器出口烟气温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_hrqckyqwd)
                      ? detail.data.f_hrqckyqwd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">换热器进水温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_hrqjswd)
                      ? detail.data.f_hrqjswd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">换热器出水温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_hrqcswd)
                      ? detail.data.f_hrqcswd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">烟囱出烟口温度</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ycckwd)
                      ? detail.data.f_ycckwd
                      : "--"
                  }}℃
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">换热器水压</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_hrqyl)
                      ? detail.data.f_hrqyl
                      : "--"
                  }}Pa
                </div>
              </div>
              <div class="equipment-control-item">
                <div class="equipment-control-left">除尘器压差</div>
                <div class="equipment-control-middle">
                  {{
                    $at.notEmpty(detail.data.f_ccqyc)
                      ? detail.data.f_ccqyc
                      : "--"
                  }}Pa
                </div>
              </div>
            </div>
          </div>
          <div class="equipment-detail-info-right">
            <div class="equipment-control-btn-wrap">
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_lmdk && detail.data.f_lmdk == '1'
                    ? 'bg-green-im white-im'
                    : ''
                "
              >
                炉门升到位
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_lmbk && detail.data.f_lmbk == '1'
                    ? 'bg-green-im white-im'
                    : ''
                "
              >
                炉门中间位
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_lmgb && detail.data.f_lmgb == '1'
                    ? 'bg-green-im white-im'
                    : ''
                "
              >
                炉门降到位
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_hlmdk && detail.data.f_hlmdk == '1'
                    ? 'bg-green-im white-im'
                    : ''
                "
              >
                灰门升到位
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_hlmgb && detail.data.f_hlmgb == '1'
                    ? 'bg-green-im white-im'
                    : ''
                "
              >
                灰门降到位
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_ksyr2 && detail.data.f_ksyr2 == '1'
                    ? 'bg-yellow-im white-im'
                    : ''
                "
              >
                开始预热
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_kshh && detail.data.f_kshh == '1'
                    ? 'bg-yellow-im white-im'
                    : ''
                "
              >
                开始火化
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_jlwc && detail.data.f_jlwc == '1'
                    ? 'bg-yellow-im white-im'
                    : ''
                "
              >
                进炉完成
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_hhwc && detail.data.f_hhwc == '1'
                    ? 'bg-yellow-im white-im'
                    : ''
                "
              >
                火化完成
              </div>
              <div
                class="equipment-control-btn bg-white gray"
                :class="
                  detail.data.f_chwc && detail.data.f_chwc == '1'
                    ? 'bg-yellow-im white-im'
                    : ''
                "
              >
                出灰完成
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="equipment-detail-right">
        <div class="equipment-detail-right-top">
          <div class="equipment-detail-title">
            {{ $at.notEmpty(detail.city) ? detail.city : "--" }}
            {{ $at.notEmpty(detail.title) ? detail.title : "--" }} 图表信息
          </div>
          <div class="equipment-detail-date-select">
            <div class="equipment-detail-date-title">选择日期：</div>
            <el-date-picker
              class="globalPicker"
              v-model="selectDate"
              type="date"
              @change="dateChange"
              value-format="yyyy-MM-dd"
              popper-class="picker-popper picker-popper-right"
              :append-to-body="false"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="equipment-detail-charts">
          <v-chart :options="options" v-if="!dataEmpty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import router from "@/router";
export default {
  name: "Detail",
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      preID: '',
      nextID: '',
      pickerOptions0: {
        disabledDate(time){
            let curDate = (new Date()).getTime();
            const month = 1
            const gap = month * 15 * 24 * 3600 * 1000;
            const oneMonth = curDate - gap;
            // 一个月包括今天的范围
            return time.getTime() > Date.now() - 8.64e6 || time.getTime() < oneMonth;
        }
      },
      options: {
        grid: {
          x: 220,
          y: 120,
          x2: 220,
          y2: 100,
        },
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
          textStyle: {
            color: "#ffffff",
            fontSize: 30,
            padding: [30],
            formatter: "{a} <br/> {c}" // 这里是鼠标移上去的显示数据
          },
        },
        dataset: [
          {
            source: [],
          },
          {
            source: [],
          },
          {
            source: [],
          },
          {
            source: [],
          },
          {
            source: [],
          },
          {
            source: [],
          },
          {
            source: [],
          },
        ],
        legend: {
          itemGap: 180,
          itemWidth: 100, // 设置宽度
          itemHeight: 50, // 设置高度
          data: [
            "主燃实际温度℃",
            "二燃实际温度℃",
            "烟道测量温度℃",
            "换热器出口烟气温度℃",
            "炉内压力Pa",
            "射流风机运行频率Hz",
            "供氧风机运行频率Hz",
          ],
          selectedMode: "single",
          inactiveColor: "#ffffff",
          textStyle: {
            color: "#4AC48F",
            fontSize: 40,
          },
          top: 0,
        },
        xAxis: {
          type: "time",
          axisLine:{
            show: true,
            lineStyle: {
              width: 5,
              color: '#ffffff'
            }
          },
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 28,
              lineHeight: 36
            }
          },
          position:"bottom",
          offset: 20
        },
        yAxis: {
          type: "value",
          axisLine:{
            show: true,
            lineStyle: {
              width: 5,
              color: '#ffffff'
            }
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 40
            }
          },
          position: "left"
        },
        series: [
          {
            name: "主燃实际温度℃",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 0,
            areaStyle: {},
          },
          {
            name: "二燃实际温度℃",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 1,
            areaStyle: {},
          },
          {
            name: "烟道测量温度℃",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 2,
            areaStyle: {},
          },
          {
            name: "换热器出口烟气温度℃",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 3,
            areaStyle: {},
          },
          {
            name: "炉内压力Pa",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 4,
            areaStyle: {},
          },
          {
            name: "射流风机运行频率Hz",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 5,
            areaStyle: {},
          },
          {
            name: "供氧风机运行频率Hz",
            type: "line",
            encode: {
              x: "recordtime",
              y: "fieldvalue",
            },
            datasetIndex: 6,
            areaStyle: {},
          },
        ],
      },
      dataEmpty: false,
      id: this.$route.query.id,
      detail: {
        data: {},
      },
      selectDate: "",
      field: [
        "f_zrscswd",
        "f_ecrscswd",
        "f_ydcswd",
        "f_hrqckyqwd",
        "f_dnyl",
        "f_slfjyxpl",
        "f_gyfjyxpl",
      ],
    };
  },
  mounted() {
    this.formatDate();
    this.loadData();
    for (let i = 0; i < this.field.length; i++) {
      this.loadCharts(this.field[i]);
    }
  },
  computed: {
    computedStatus() {
      return function (params) {
        if (params) {
          if (params == 1) {
            return "自动";
          } else {
            return "手动";
          }
        } else {
          return "手动";
        }
      };
    },
    computedFireStatus() {
      return function (params) {
        if (params) {
          if (params == 1) {
            return "点火";
          } else {
            return "未点";
          }
        } else {
          return "未点";
        }
      };
    },
  },
  methods: {
    back() {
      router.go(-1);
    },
    pre(){
      this.id = this.preID
      this.formatDate();
      this.loadData();
      for (let i = 0; i < this.field.length; i++) {
        this.loadCharts(this.field[i]);
      }
    },
    next(){
      this.id = this.nextID
      this.formatDate();
      this.loadData();
      for (let i = 0; i < this.field.length; i++) {
        this.loadCharts(this.field[i]);
      }
    },
    formatDate() {
      let dateTime = dayjs();
      this.selectDate = dateTime.format("YYYY-MM-DD");
    },
    dateChange() {
      for (let i = 0; i < this.field.length; i++) {
        this.loadCharts(this.field[i]);
      }
    },
    loadData() {
      const loading = this.$loading({
        customClass: 'loading-style',
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _self = this;
      _self.$api
        .getEquipment(_self.id)
        .then((res) => {
          loading.close();
          _self.detail = res.data;
          _self.preID = res.data.prev_id;
          _self.nextID = res.data.next_id;
        })
        .catch(() => {
          loading.close();
        });
    },
    loadCharts(fieldname) {
      let _self = this;
      _self.$api
        .getEquipmentCharts({
          equipment_id: _self.id,
          fieldname: fieldname,
          date: _self.selectDate || "",
        })
        .then((res) => {
          console.log(res);
          switch (fieldname) {
            case 'f_zrscswd':
              _self.options.dataset[0].source = res.data;
              break;
            case 'f_ecrscswd':
              _self.options.dataset[1].source = res.data;
              break;
            case 'f_ydcswd':
              _self.options.dataset[2].source = res.data;
              break;
            case 'f_hrqckyqwd':
              _self.options.dataset[3].source = res.data;
              break;
            case 'f_dnyl':
              _self.options.dataset[4].source = res.data;
              break;
            case 'f_slfjyxpl':
              _self.options.dataset[5].source = res.data;
              break;
            case 'f_gyfjyxpl':
              _self.options.dataset[6].source = res.data;
              break;
            default:
              break;
          }

        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.detail .back-btn {
  width: 500px;
  justify-content: center;
  margin: 100px 0 100px 100px;
}

.equipment-detail-content {
  padding: 0 100px 50px;
  display: flex;
  .equipment-detail-left {
    width: 2200px;
    min-width: 2200px;
    margin-right: 50px;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 80px 90px;
    .equipment-detail-title {
      margin-bottom: 60px;
    }
  }
  .equipment-detail-right {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 80px 90px;
    .equipment-detail-right-top {
      margin-bottom: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .equipment-detail-date-select {
        display: flex;
        align-items: center;
        .equipment-detail-date-title {
          font-size: 60px;
          color: #fff;
          margin-right: 30px;
          font-weight: bold;
        }
        .globalPicker.el-date-editor.el-input,
        .globalPicker.el-date-editor.el-input__inner {
          width: 300px;
          margin-right: 50px;
        }
        .globalPicker .el-input__inner {
          height: 90px;
          line-height: 90px;
          border-radius: 0;
          padding: 0 30px;
          font-size: 30px;
          font-weight: bold;
          color: #707070;
        }
        .el-input__icon {
          font-size: 30px;
          display: none;
        }
      }
    }
    .equipment-detail-charts {
      width: 100%;
      .echarts {
        width: 100%;
        height: 1100px;
      }
    }
  }
  .equipment-detail-title {
    font-size: 60px;
    color: #fff;
    font-weight: bold;
  }
}
.equipment-detail-info {
  display: flex;
  .equipment-detail-info-left,
  .equipment-detail-info-middle {
    width: 800px;
    min-width: 800px;
    padding-right: 90px;
    margin-right: 90px;
    min-height: 100%;
    border-right: 2px solid rgba(255, 255, 255, 0.2);
  }
  .equipment-detail-info-right {
    flex: 1;
    min-height: 100%;
  }
  .equipment-control {
    .equipment-control-item {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      font-size: 30px;
      .equipment-control-left {
        color: #fff;
        margin-right: 100px;
        width: 270px;
        min-width: 240px;
        text-align: right;
      }
      .equipment-control-middle {
        flex: 1;
        width: 0;
        color: #fff;
        font-weight: bold;
      }
      .equipment-control-right {
        margin-left: 30px;
        width: 100px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #010147;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
  .equipment-control-btn-wrap {
    .equipment-control-btn {
      margin-bottom: 50px;
      width: 100%;
      height: 60px;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-size: 22px;
      line-height: 1;
      font-weight: bold;
    }
  }
}
.btn-wrap {
  display: flex;
  align-items: center;
  .global-btn{
    margin-right: 60px;
  }
}
.loading-style{
  .el-icon-loading{
    font-size: 200px !important;
    color: #fff !important;
  }
  .el-loading-text{
    font-size: 100px !important;
    color: #fff !important;
  }
}
</style>