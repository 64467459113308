<template>
  <div class="statistics">
    <div class="page-left">
      <div class="page-left-title">运行参数统计</div>
      <el-tabs class="params-wrap" v-model="activeTabName" @tab-click="handleClick">
        <el-tab-pane label="燃气火化机" name="m³">
          <div class="params-list">
            <div class="params-item">
              <div class="params-item-left">平均每天火化数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYtsCount) ? run.avgYtsCount : '--'}}</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单日处理最高数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.maxYtsCount) ? run.maxYtsCount : '--'}}</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台单日处理最高数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.maxYtsSingleCount) ? run.maxYtsSingleCount.equipment.title : '--'}} <span class="RC">{{$at.notEmpty(run.maxYtsSingleCount) ? run.maxYtsSingleCount.yts : '--'}}</span> 具</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">平均每具能耗</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYthnCount) ? run.avgYthnCount : '--'}}（立方米）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">平均预热时长</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYrscCount) ? run.avgYrscCount : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单次平均燃烧时长</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYthsCount) ? run.avgYthsCount : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具燃烧最长</div>
              <div class="params-item-right">{{run.ythsMax&&$at.notEmpty(run.ythsMax.equipment.title) ? run.ythsMax.equipment.title : '--'}} {{run.ythsMax&&$at.notEmpty(run.ythsMax.yths) ? run.ythsMax.yths : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具燃烧最短</div>
              <div class="params-item-right">{{run.ythsMin&&$at.notEmpty(run.ythsMin.equipment.title) ? run.ythsMin.equipment.title : '--'}} {{run.ythsMin&&$at.notEmpty(run.ythsMin.yths) ? run.ythsMin.yths : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具能耗最高</div>
              <div class="params-item-right">{{run.ythnMax&&$at.notEmpty(run.ythnMax.equipment.title) ? run.ythnMax.equipment.title : '--'}} {{run.ythnMax&&$at.notEmpty(run.ythnMax.ythn) ? run.ythnMax.ythn : '--'}}（立方米）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具能耗最低</div>
              <div class="params-item-right">{{run.ythnMin&&$at.notEmpty(run.ythnMin.equipment.title) ? run.ythnMin.equipment.title : '--'}} {{run.ythnMin&&$at.notEmpty(run.ythnMin.ythn) ? run.ythnMin.ythn : '--'}}（立方米）</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="燃油火化机" name="L">
          <div class="params-list">
            <div class="params-item">
              <div class="params-item-left">平均每天火化数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYtsCount) ? run.avgYtsCount : '--'}}</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单日处理最高数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.maxYtsCount) ? run.maxYtsCount : '--'}}</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台单日处理最高数量</div>
              <div class="params-item-right">{{$at.notEmpty(run.maxYtsSingleCount) ? run.maxYtsSingleCount.equipment.title : '--'}} <span class="RC">{{$at.notEmpty(run.maxYtsSingleCount) ? run.maxYtsSingleCount.yts : '--'}}</span> 具</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">平均每具能耗</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYthnCount) ? run.avgYthnCount : '--'}}（升）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">平均预热时长</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYrscCount) ? run.avgYrscCount : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单次平均燃烧时长</div>
              <div class="params-item-right">{{$at.notEmpty(run.avgYthsCount) ? run.avgYthsCount : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具燃烧最长</div>
              <div class="params-item-right">{{run.ythsMax&&$at.notEmpty(run.ythsMax.equipment.title) ? run.ythsMax.equipment.title : '--'}} {{run.ythsMax&&$at.notEmpty(run.ythsMax.yths) ? run.ythsMax.yths : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具燃烧最短</div>
              <div class="params-item-right">{{run.ythsMin&&$at.notEmpty(run.ythsMin.equipment.title) ? run.ythsMin.equipment.title : '--'}} {{run.ythsMin&&$at.notEmpty(run.ythsMin.yths) ? run.ythsMin.yths : '--'}}（分钟）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具能耗最高</div>
              <div class="params-item-right">{{run.ythnMax&&$at.notEmpty(run.ythnMax.equipment.title) ? run.ythnMax.equipment.title : '--'}} {{run.ythnMax&&$at.notEmpty(run.ythnMax.ythn) ? run.ythnMax.ythn : '--'}}（升）</div>
            </div>
            <div class="params-item">
              <div class="params-item-left">单台设备单具能耗最低</div>
              <div class="params-item-right">{{run.ythnMin&&$at.notEmpty(run.ythnMin.equipment.title) ? run.ythnMin.equipment.title : '--'}} {{run.ythnMin&&$at.notEmpty(run.ythnMin.ythn) ? run.ythnMin.ythn : '--'}}（升）</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="temperature-wrap">
        <div class="temperature-item">
          <div class="temperature-item-top">
            <div class="temperature-img-wrap">
              <div class="temperature-img"><img src="@/assets/images/icon/temperature.png" alt=""></div>
              <div class="temperature-progress"><el-progress class="global-progress" :stroke-width="10" :percentage="run.avgZrswdCount ? Number(run.avgZrswdCount)/10 : 0" :show-text="false"></el-progress></div>
              <div class="temperature RC">{{$at.notEmpty(run.avgZrswdCount) ? run.avgZrswdCount : '--'}}&deg;C</div>
            </div>
          </div>
          <div class="temperature-item-bottom">
            <div class="temperature-item-text">主燃</div>
            <div class="temperature-item-text">平均燃烧温度</div>
          </div>
        </div>
        <div class="temperature-item">
          <div class="temperature-item">
            <div class="temperature-item-top">
              <div class="temperature-img-wrap">
                <div class="temperature-img"><img src="@/assets/images/icon/temperature.png" alt=""></div>
                <div class="temperature-progress"><el-progress class="global-progress" :stroke-width="10" :percentage="run.avgEcrswdCount ? Number(run.avgEcrswdCount)/10 : 0" :show-text="false"></el-progress></div>
                <div class="temperature RC">{{$at.notEmpty(run.avgEcrswdCount) ? run.avgEcrswdCount : '--'}}&deg;C</div>
              </div>
            </div>
            <div class="temperature-item-bottom">
              <div class="temperature-item-text">二燃</div>
              <div class="temperature-item-text">平均燃烧温度</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 中间数据统计 -->
    <div class="page-middle">

      <!-- 筛选 -->
      <div class="filter-form statistics-filter-form">
        <el-select v-model="selectCity" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择城市" @change="changeCity" :popper-append-to-body="false">
          <el-option
            v-for="item in city"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="selectCompany" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择公司" :popper-append-to-body="false">
          <el-option
            v-for="item in company"
            :key="item.id"
            :label="item.title"
            :value="item.id"
            :disabled="disabled">
          </el-option>
        </el-select>
        <el-select v-model="selectModel" class="globalSelect globalSelectSm" popper-class="globalSelectPopper" placeholder="请选择炉型" :popper-append-to-body="false">
          <el-option
            v-for="item in model"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          class="globalPicker"
          v-model="startDate"
          type="date"
          value-format="yyyy-MM-dd"
          popper-class="picker-popper"
          placeholder="--统计开始时间--" :append-to-body="false">
        </el-date-picker>
        <el-date-picker
          class="globalPicker"
          v-model="endDate"
          type="date"
          value-format="yyyy-MM-dd"
          popper-class="picker-popper"
          placeholder="--统计结束时间--" :append-to-body="false">
        </el-date-picker>
        <el-button class="global-btn" type="primary" @click="submit"><img src="@/assets/images/icon/search.png" alt="">筛选</el-button>
      </div>

      <!-- 数据展示 -->
      <div class="statistics-info-wrap">
        <div class="statistics-info">

          <div class="statistics-common">
            <!-- 汇总统计，随筛选仅数据变化 -->
            <div class="statistics-first" v-if="!showSearchCompany">
              <div class="statistics-first-item">
                <div class="statistics-first-text">服务殡仪馆</div>
                <div class="statistics-first-number RC">{{$at.notEmpty(statistics.countCompanies) ? statistics.countCompanies : '--'}}</div>
                <div class="statistics-first-text">家</div>
              </div>
              <div class="statistics-first-item">
                <div class="statistics-first-text">生产线运行</div>
                <div class="statistics-first-number RC">{{$at.notEmpty(statistics.countEquipment) ? statistics.countEquipment : '--'}}</div>
                <div class="statistics-first-text">(拣灰{{$at.notEmpty(statistics.ashtype) ? statistics.ashtype.jianhuilu : '--'}}/平板{{$at.notEmpty(statistics.ashtype) ? statistics.ashtype.pingbanlu : '--'}})台</div>
              </div>
              <div class="statistics-first-item">
                <div class="statistics-first-text">运行中</div>
                <div class="statistics-first-number RC">{{$at.notEmpty(statistics.countRuningEquipment) ? statistics.countRuningEquipment : '--'}}</div>
                <div class="statistics-first-text">台</div>
              </div>
              <div class="statistics-first-item">
                <div class="statistics-first-text">{{$at.notEmpty(statistics.currentYear) ? statistics.currentYear : '--'}}年处理总数量</div>
                <div class="statistics-first-number RC"><span class="RC">{{$at.notEmpty(statistics.currentYearYts) ? statistics.currentYearYts : '--'}}</span></div>
                <div class="statistics-first-text">具</div>
              </div>
            </div>

            <!-- 筛选统计，随筛选数据项变化  -->
            <div class="statistics-company" v-else>
              <div class="statistics-title">{{$at.notEmpty(statistics.maxYtsSingle.company.title) ? statistics.maxYtsSingle.company.title : '--'}}</div>
              <div class="statistics-first">
                <div class="statistics-first-item">
                  <div class="statistics-first-text">平均能耗</div>
                  <div class="statistics-first-number RC">{{$at.notEmpty(statistics.forSearchCompany.avgYthnCount) ? statistics.forSearchCompany.avgYthnCount : '--'}}</div>
                  <div class="statistics-first-text">m<sup>3</sup></div>
                </div>
                <div class="statistics-first-item">
                  <div class="statistics-first-text">平均耗时</div>
                  <div class="statistics-first-number RC">{{$at.notEmpty(statistics.forSearchCompany.avgYthsCount) ? statistics.forSearchCompany.avgYthsCount : '--'}}</div>
                  <div class="statistics-first-text">分钟</div>
                </div>
                <div class="statistics-first-item">
                  <div class="statistics-first-text">故障次数</div>
                  <div class="statistics-first-number RC">{{$at.notEmpty(statistics.forSearchCompany.faultRate) ? statistics.forSearchCompany.faultRate : '--'}}</div>
                  <div class="statistics-first-text">次</div>
                </div>
              </div>
            </div>
          </div>

          <div class="statistics-fourth">
            <div class="statistics-fourth-item">
              <div class="statistics-fourth-number"><span class="RC">{{$at.notEmpty(statistics.avgYtsCount) ? statistics.avgYtsCount : '--'}}</span> 具</div>
              <div class="statistics-fourth-text">每日平均处理数量</div>
            </div>
            <div class="statistics-fourth-item">
              <div class="statistics-fourth-number"><span class="RC">{{$at.notEmpty(statistics.avgYtsMonthCount) ? statistics.avgYtsMonthCount : '--'}}</span> 具</div>
              <div class="statistics-fourth-text">每月平均处理数量</div>
            </div>
            <div class="statistics-fifth" v-if="!showSearchCompany">
              <div class="statistics-fifth-number"><span class="RC">{{$at.notEmpty(statistics.maxYtsSingle) ? statistics.maxYtsSingle.yts : '--'}}</span> 具</div>
              <div class="statistics-fifth-text">全年单台单日火化机处理最高数量</div>
              <div class="statistics-fifth-title">{{$at.notEmpty(statistics.maxYtsSingle) ? statistics.maxYtsSingle.company.title : '--'}}</div>
            </div>
          </div>

          <div class="statistics-third">
            <div class="statistics-third-img"><v-chart :options="options" v-if="!dataEmpty" /></div>
            <div class="statistics-third-text">近12个月数据汇总</div>
          </div>

        </div>
      </div>

    </div>

    <div class="page-right" @mouseover="scrollerEnter" @mouseout="scrollerOut">
      <transition name="el-fade-in-linear">
        <div class="count-wrap">
          <div class="count-top">
            <el-tabs class="params-wrap" v-model="activeTabName" @tab-click="handleClick">
              <el-tab-pane label="燃气火化机" name="m³">
                <!-- <div class="ld-chart-wrap">
                  <div class="ld-chart"><v-chart :options="ldOptionA" v-if="!dataEmpty" /></div>
                  <div class="ld-chart"><v-chart :options="ldOptionB" v-if="!dataEmpty" /></div>
                </div>
                <div class="count-main-title">{{dateRange}}</div>
                <div class="count-item">
                  <div class="count-first">炉号</div>
                  <div class="count-second">总能耗<br/>m³</div>
                  <div class="count-third">总遗体数<br/>/具</div>
                  <div class="count-fourth">平均能耗<br/>m³</div>
                  <div class="count-fifth">平均耗时<br/>/分钟</div>
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="燃油火化机" name="L">
                <!-- <div class="ld-chart-wrap">
                  <div class="ld-chart"><v-chart :options="ldOptionA" v-if="!dataEmpty" /></div>
                  <div class="ld-chart"><v-chart :options="ldOptionB" v-if="!dataEmpty" /></div>
                </div>
                <div class="count-main-title">{{dateRange}}</div>
                <div class="count-item">
                  <div class="count-first">炉号</div>
                  <div class="count-second">总能耗<br/>L</div>
                  <div class="count-third">总遗体数<br/>/具</div>
                  <div class="count-fourth">平均能耗<br/>L</div>
                  <div class="count-fifth">平均耗时<br/>/分钟</div>
                </div> -->
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="ld-chart-wrap">
            <div class="ld-chart">
              <div class="count-main-title">拣灰炉</div>
              <v-chart :options="ldOptionA" v-if="!dataEmpty" /></div>
            <div class="ld-chart">
              <div class="count-main-title">平板炉</div>
              <v-chart :options="ldOptionB" v-if="!dataEmpty" /></div>
          </div>
          <div class="count-main-title">{{dateRange}}</div>
          <div class="count-item">
            <div class="count-first">炉号</div>
            <div class="count-second">总能耗<br/>{{selectModel}}</div>
            <div class="count-third">总遗体数<br/>/具</div>
            <div class="count-fourth">平均能耗<br/>{{selectModel}}</div>
            <div class="count-fifth">平均耗时<br/>/分钟</div>
            <div class="count-fifth">碳排放</div>
          </div>
          <div v-show="show">
            <div class="count-bottom">
              <vueInfiniteAutoScroll
              ref="scroller"
              :data="list"
              :option="{force:true, speed:2}"
              class="scroller-wrap"
              key="s1">
                <template slot-scope="props">
                  <div class="count-list">
                    <!-- <div class="count-sub-title">{{$at.notEmpty(props.item.equipment.company.city) ? props.item.equipment.company.city.title : '--'}} {{$at.notEmpty(props.item.equipment.company.title) ? props.item.equipment.company.title : '--'}}</div> -->
                    <div class="count-item">
                      <div class="count-first">{{$at.notEmpty(props.item.equipment.title) ? props.item.equipment.title : '--'}}</div>
                      <div class="count-second">{{$at.notEmpty(props.item.sum_ythn) ? props.item.sum_ythn : '--'}}</div>
                      <div class="count-third">{{$at.notEmpty(props.item.sum_yts) ? props.item.sum_yts : '--'}}</div>
                      <div class="count-fourth">{{$at.notEmpty(props.item.avg_ythn) ? props.item.avg_ythn : '--'}}</div>
                      <div class="count-fifth">{{$at.notEmpty(props.item.avg_yths) ? props.item.avg_yths : '--'}}</div>
                      <div class="count-sixth">0</div>
                    </div>
                  </div>
                </template>
              </vueInfiniteAutoScroll>
            </div>
          </div>
        </div>
      </transition>
      <el-pagination
        v-if="lastPage>1"
        class="global-pagination"
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-count="lastPage"
        >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/radar";
import vueInfiniteAutoScroll from 'vue-infinite-auto-scroll'
export default {
  name: "Statistics",
  components: {
    "v-chart": ECharts,
    vueInfiniteAutoScroll
  },
  data() {
    return {
      options:{
        series: {
          type: "bar",
          data: [],
          color: "#ffffff",
          barWidth: 10,
          label: { show: true, position: "top", fontSize: 20},
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine:{
            show: true,
            lineStyle: {
              width: 2,
              color: '#707070'
            }
          },
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 25
            }
          }
        },
        yAxis: {
          show:false,
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 25
            }
          }
        }
      },
      activeTabName: 'm³',
      city: [],
      company: [],
      dataEmpty: false,
      disabled: false,
      model: [
        {
          value: 'm³',
          label: '燃气火化机'
        },
        {
          value: 'L',
          label: '燃油火化机'
        }
      ],
      selectCity: '',
      selectCompany: '',
      selectModel: 'm³',
      startDate: '',
      endDate: '',
      show: true,
      page: 1,
      lastPage: 1,
      list: [],
      run: {},
      statistics: {},
      showSearchCompany: false,
      dateRange: '--',
      // 雷达图
      ldOptionA: {
        title: {},
        tooltip: {},
        legend: {
          data: ['平均能耗', '平均耗时'],
          textStyle: {
            fontSize: 22,
            color: '#fff',
          }
        },
        radar: {
          name: {
            textStyle: {
              fontSize: 22,
              color: '#fff',
              backgroundColor: '#000',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          radius: '60%',
          splitArea: {
            areaStyle: {
              color: ['rgba(255,255,255,.08)','rgba(255,255,255,.1)']
            }
          },
          splitLine: {
            lineStyle: {
              color: ['rgba(255,255,255,.16)']
            }
          },
          indicator: [
            { name: '--'},
            { name: '--'},
            { name: '--'},
            { name: '--'},
            { name: '--'}
          ]
        },
        series: [{
          name: '平均能耗/平均耗时',
          type: 'radar',
          data: [
            {
              value: [0, 0, 0, 0, 0],
              name: '平均能耗'
            },
            {
              value: [0, 0, 0, 0, 0],
              name: '平均耗时'
            }
          ]
        }]
      },
      ldOptionB: {
        title: {},
        tooltip: {},
        legend: {
          data: ['平均能耗', '平均耗时'],
          textStyle: {
            fontSize: 22,
            color: '#fff',
          }
        },
        radar: {
          name: {
            textStyle: {
              fontSize: 22,
              color: '#fff',
              backgroundColor: '#000',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          radius: '60%',
          splitArea: {
            areaStyle: {
              color: ['rgba(255,255,255,.08)','rgba(255,255,255,.1)']
            }
          },
          splitLine: {
            lineStyle: {
              color: ['rgba(255,255,255,.16)']
            }
          },
          indicator: [
            { name: '--'},
            { name: '--'},
            { name: '--'},
            { name: '--'},
            { name: '--'}
          ]
        },
        series: [{
          name: '平均能耗/平均耗时',
          type: 'radar',
          data: [
            {
              value: [0, 0, 0, 0, 0],
              name: '平均能耗'
            },
            {
              value: [0, 0, 0, 0, 0],
              name: '平均耗时'
            }
          ]
        }]
      }
    }
  },
  mounted() {
    this.page = 1
    this.list = []
    this.loadData();
    this.loadRun();
    this.loadStatistics();
    this.loadList();
    this.loadRadar('jianhuilu')
    this.loadRadar('pingbanlu')
  },
  methods: {
    scrollerEnter() {
      this.$refs.scroller.stop()
    },
    scrollerOut() {
      this.$refs.scroller.start();
    },

    handleClick() {
      this.selectModel = this.activeTabName
      this.loadRun();
      this.loadList();
      this.loadStatistics();
      this.loadRadar('jianhuilu')
      this.loadRadar('pingbanlu')
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadList(val);
    },
    changeCity(e){
      let _self = this
      _self.disabled = true
      _self.$api.getCompanies({
        city_id: e
      })
      .then((res) => {
        _self.company = res.data
        _self.disabled = false
      })
      .catch(() => {
        _self.disabled = false
      });
    },
    loadData(){
      let _self = this
      _self.$api.getCities()
      .then((res) => {
        _self.city = res.data
      })
      .catch(() => {});

      _self.$api.getCompanies({
        city_id: this.selectCity || ''
      })
      .then((res) => {
        _self.company = res.data
      })
      .catch(() => {});
    },
    loadRun(){
      let _self = this
      _self.run = {}
      _self.$api.getStatisticRun({
        city_id: _self.selectCity || '',
        company_id: _self.selectCompany || '',
        energytype: _self.selectModel || 'm³',
        start_date: _self.startDate || '',
        end_date: _self.endDate || '',
      })
      .then((res) => {
        _self.run=res.data
      })
      .catch(() => {});
    },
    loadStatistics(){
      let _self = this
      if(_self.show){
        _self.show = false
      }
      _self.$api.getStatistic({
        city_id: _self.selectCity || '',
        company_id: _self.selectCompany || '',
        energytype: _self.selectModel || 'm³',
        start_date: _self.startDate || '',
        end_date: _self.endDate || '',
      })
      .then((res) => {
        _self.statistics=res.data
        if(_self.statistics.forSearchCompany != ""){
          this.showSearchCompany = true
        }
        let chartData = res.data.monthsDetail
        if(!chartData || chartData.length==0){
          _self.dataEmpty = true
        }else{
          _self.dataEmpty = false
          let xAxis = []
          let series = []
          for(let i = 0; i<chartData.length; i++){
            xAxis.push(chartData[i].month)
            series.push(chartData[i].count_yts)
          }
          _self.options.xAxis.data = xAxis
          _self.options.series.data = series
        }
      })
      .catch(() => {});
    },
    loadList(page){
      let _self = this
      if(_self.show){
        _self.show = false
      }
      _self.$api.getStatisticYear({
        city_id: _self.selectCity || '',
        company_id: _self.selectCompany || '',
        energytype: _self.selectModel || 'm³',
        start_date: _self.startDate || '',
        end_date: _self.endDate || '',
        page: page || this.page,
      })
      .then((res) => {
        _self.show = true
        _self.dateRange = res.data.date_range
        _self.list=res.data.list
        _self.lastPage = res.meta.last_page
      })
      .catch(() => {});
    },
    loadRadar(ashtype){
      let _self = this
      _self.$api.getStatisticRadar({
        ashtype: ashtype,
        energytype: _self.selectModel || 'm³',
        start_date: _self.startDate || '',
        end_date: _self.endDate || ''
      })
      .then((res) => {
        let resData = res.data
        if(resData && resData.length>0){
          if(ashtype == 'jianhuilu'){
            let aryName = []
            let aryHn = []
            let aryHs = []
            for (let i = 0; i < resData.length; i++) {
              aryName.push({
                name: resData[i].equipment.title_abbr,
                min: 0,
                max: 100
              })
              aryHn.push(resData[i].avg_ythn)
              aryHs.push(resData[i].avg_yths)
            }
            _self.ldOptionA.radar.indicator = aryName
            _self.ldOptionA.series[0].data[0].value = aryHn
            _self.ldOptionA.series[0].data[1].value = aryHs
          }else{
            let aryName = []
            let aryHn = []
            let aryHs = []
            for (let i = 0; i < resData.length; i++) {
              aryName.push({
                name: resData[i].equipment.title_abbr,
                min: 0,
                max: 100
              })
              aryHn.push(resData[i].avg_ythn)
              aryHs.push(resData[i].avg_yths)
            }
            _self.ldOptionB.radar.indicator = aryName
            _self.ldOptionB.series[0].data[0].value = aryHn
            _self.ldOptionB.series[0].data[1].value = aryHs
          }
        }
      })
      .catch(() => {});
    },
    submit(){
      this.activeTabName = this.selectModel
      this.page = 1
      this.loadRun();
      this.loadStatistics();
      this.loadList();
      this.loadRadar('jianhuilu')
      this.loadRadar('pingbanlu')
    },
  }
};
</script>
<style lang="scss">
.statistics .page-left{
  width: 1680px;
  padding: 350px 200px 50px;
}
.page-left-title{
  font-size: 80px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px;
}
.params-wrap .el-tabs__header{
  margin-bottom: 50px;
}
.params-wrap .el-tabs__item{
  height: 60px;
  line-height: 1;
  font-size: 60px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 25px;
  padding-left: 0;
  padding-right: 340px;
}
.params-wrap .el-tabs__nav-wrap::after{
  content: none;
}
.params-wrap .el-tabs__item.is-active{
  color: #fff;
}
.params-wrap .el-tabs__active-bar{
  width: 200px !important;
  height: 8px;
  background-color: #fff;
}
.params-wrap .el-tabs__item:hover{
  color: #fff;
}
.params-list{
  font-size: 38px;
  color: #fff;
  margin-bottom: 40px;
  .params-item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .params-item-left{
      min-width: 600px;
      width: 600px;
      margin-right: 40px;
      color: rgba(255, 255, 255, 0.6);
    }
    .params-item-right{
      flex: 1;
      font-weight: bold;
    }
  }
}
.page-middle{
  width: 3360px;
  margin: 0 auto;
}
.statistics-filter-form .globalSelect.el-select .el-input__inner{
  width: 600px;
}
.statistics-filter-form .globalPicker.el-date-editor.el-input,
.statistics-filter-form .globalPicker.el-date-editor.el-input__inner{
  width: 474px;
  margin-right: 50px;
}
.statistics-filter-form .globalPicker .el-input__inner{
  height: 90px;
  line-height: 90px;
  border-radius: 0;
  padding: 0 30px;
  font-size: 30px;
  font-weight: bold;
  color: #707070;
}
.statistics-filter-form .el-input__icon{
  font-size: 30px;
  display: none;
}
.picker-popper.el-date-picker{
  width: 474px;
  top: 90px !important;
  left: 0 !important;
}
.picker-popper.picker-popper-right.el-date-picker{
  left: auto !important;
  right: 0 !important;
}
.picker-popper.el-date-picker .el-picker-panel__content{
  width: 447px;
}
.picker-popper .el-date-picker__header-label {
  font-size: 24px;
  line-height: 36px;
}
.picker-popper .el-month-table,
.picker-popper .el-year-table,
.picker-popper .el-picker-panel__icon-btn,
.picker-popper .el-date-table{
  font-size: 20px;
}
.picker-popper .el-date-table th{
  padding: 10px 5px;
}
.picker-popper .el-date-table td {
  width: 30px;
  height: 30px;
  padding: 12px 0;
}
.picker-popper .el-date-table td div{
  height: 30px;
  padding: 0;
}
.picker-popper .el-date-table td span{
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.picker-popper.el-popper[x-placement^=bottom] .popper__arrow::after {
  top: -6px;
  margin-left: -12px;
}
.picker-popper.el-popper .popper__arrow::after{
  border-width: 12px;
}
.statistics-info-wrap{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  .statistics-info{
    max-width: 2560px;
    .statistics-company{
      .statistics-title{
        font-size: 80px;
        text-align: center;
        margin-bottom: 40px;
      }
    }
    .statistics-first{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 25px;
      margin-bottom: 25px;
      &-item{
        margin: 0 20px;
        width: 580px;
        text-align: center;
        .statistics-first-number{
          font-size: 160px;
        }
        .statistics-first-text{
          font-size: 50px;
          color: #fff;
        }
      }
    }
    .statistics-second{
      text-align: center;
      margin-bottom: 60px;
      .statistics-second-number{
        font-size: 40px;
        span{
          font-size: 120px;
        }
      }
      .statistics-second-text{
        font-size: 40px;
        color: #fff;
      }
    }
    .statistics-third{
      padding-bottom: 25px;
      margin-bottom: 25px;
      text-align: center;
      .statistics-third-img{
        width: 1680px;
        height: 400px;
        margin: 0 auto;
        .echarts{
          width: 100%;
          height: 100%;
        }
      }
      .statistics-third-text{
        font-size: 40px;
        color: #fff;
      }
    }
    .statistics-fourth{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 25px;
      &-item{
        margin: 0 100px;
        width: 360px;
        text-align: left;
        .statistics-fourth-number{
          font-size: 40px;
          span{
            font-size: 120px;
          }
        }
        .statistics-fourth-text{
          font-size: 40px;
          color: #fff;
        }
      }
    }
    .statistics-fifth{
      text-align: center;
      .statistics-fifth-number{
        font-size: 40px;
        span{
          font-size: 120px;
        }
      }
      .statistics-fifth-text{
        font-size: 40px;
        color: #fff;
      }
      .statistics-fifth-title{
        font-size: 40px;
        font-weight: bold;
        color: #fff;
      }
    }
    .statistics-common{
      background-color: rgba(0,0,0,0.2);
      border: 2px solid rgba(255, 255, 255, 0.2);
      margin-top: 75px;
      margin-bottom: 75px;
      padding-top: 75px;
      padding-bottom: 75px;
      border-radius: 20px;
    }
  }
}
.page-right{
  width: 1680px;
}
.temperature-wrap{
  margin-top: 30px;
  display: flex;
  .temperature-item{
    width: 500px;
    text-align: center;
    .temperature-item-top{
      display: flex;
      justify-content: center;
      .temperature-img-wrap{
        position: relative;
        .temperature-img{
          line-height: 0;
          img{
            max-height: 500px;
          }
        }
        .temperature-progress{
          transform: rotate(-90deg);
          transform-origin: 0 0;
          position: absolute;
          left: 24px;
          bottom: 36px;
          width: 443px;
          height: 10px;
        }
        .temperature{
          position: absolute;
          top: 50px;
          left: 100%;
          margin-left: 22px;
          font-size: 60px;
          min-width: 200px;
          text-align: left;
        }
      }
    }
    .temperature-item-bottom{
      margin-top: 35px;
      .temperature-item-text{
        font-size: 30px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
.global-progress .el-progress-bar__outer{
  background-color: #fff;
}
.global-progress .el-progress-bar__inner{
  background-color: #0F46AA;
}
.global-progress .el-progress-bar__outer,
.global-progress .el-progress-bar__inner{
  border-radius: 0 10px 10px 0;
}
.statistics .page-right{
  width: 1680px;
  padding: 270px 200px 50px 200px;
}
.count-main-title{
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 45px;
}
.count-wrap{
  min-height: 1660px;
  .count-sub-title{
    font-size: 36px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    // margin-bottom: 20px;
  }
  .count-list{
    padding-bottom: 40px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 40px;
  }
  .count-item{
    font-size: 32px;
    line-height: 1.2;
    color: #fff;
    display: flex;
    // margin-bottom: 20px;
    .count-first{
      width: 420px;
      margin-right: 20px;
    }
    .count-second{
      width: 130px;
      margin-right: 20px;
    }
    .count-third{
      width: 150px;
      margin-right: 20px;
    }
    .count-fourth{
      width: 170px;
      margin-right: 20px;
    }
    .count-fifth{
      flex: 1;
    }
    .count-sixth{
      width: 100px;
    }
  }
  .count-top{
    .count-list{
      .count-item{
        font-weight: bold;
      }
    }
  }
}
.scroller-wrap {
	height: 800px;
}
.ld-chart-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 75px;
  .ld-chart{
    margin: 0 auto;
    width: 600px;
    height: 600px;
    .echarts{
      width: 100%;
      height: 100%;
    }
  }
  .count-main-title{
    font-size: 40px;
    text-align: center;
  }
}
</style>