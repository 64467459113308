<template>
  <div class="distribution">
    <div class="page-left">
      <div class="page-left-title">客户案例</div>
      <div v-for="(item, index) in list" :key="index">
        <div v-for="(subItem, subIndex) in item.portfolios" :key="subIndex" @click="read(subItem.id)" class="portfolio-item" :class="subItem.id == id ? 'active-portfolio-item' : '' ">{{subItem.title}}</div>
      </div>
    </div>
    <div class="page-middle">
      <DashboardMap :map="map" />
    </div>
    <div class="distribution-right-content-wrap">
      <div class="distribution-right-content-title" v-if="info&&info.length>0">{{info[0].title}}</div>
      <div @mouseover="scrollerEnter" @mouseout="scrollerOut">
      <vueInfiniteAutoScroll
        ref="scroller"
        :data="info"
        :option="{force:true, speed:2}"
        class="content-scroller-wrap"
        key="s1">
        <template slot-scope="props">
          <div class="distribution-right-content-info" v-html="props.item.content" ref="content" @click="imageEnlargement"></div>
        </template>
      </vueInfiniteAutoScroll>
      </div>
      <swiper class="swiper-wrapper" :options="swiperOption" v-if="imageList&&imageList.length>0">
        <swiper-slide v-for="(item, index) in imageList" :key="index"><img @click="showImg(index)" :src="item" alt="" /></swiper-slide>
      </swiper>
    </div>
    <CoolLightBox
      :items="url"
      :index="index"
      loop
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import DashboardMap from "@/components/Map/EchartsMap.vue";
import vueInfiniteAutoScroll from 'vue-infinite-auto-scroll'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  name: "Portfolios",
  components: {
    DashboardMap,
    vueInfiniteAutoScroll,
    CoolLightBox
  },
  data() {
    return {
      map: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true
      },
      activeName: '',
      companyInfo: {},
      list: [],
      id: 0,
      info: [],
      imageList: [],
      index: null,
      url: [],
      showViewer: false
    };
  },
  mounted() {
    this.loadList()
    this.loadMap()
  },
  methods: {
    scrollerEnter() {
      this.$refs.scroller.stop()
    },
    scrollerOut() {
      this.$refs.scroller.start();
    },
    showImg(index){
      this.url = this.imageList
      this.index = index
    },
    imageEnlargement(e){
      if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
        this.url = [e.target.currentSrc]
        this.index = 0
      } else {
        console.log("点击内容不为img")
      }
    },
    loadList(){
      let _self = this
      _self.$api.portfolioCategory({
        withItem: 1
      })
      .then((res) => {
        if(_self.$at.notEmpty(res.data)){
          _self.activeName = res.data[0].id
          _self.list = res.data
          if(_self.$at.notEmpty(res.data[0].portfolios)){
            _self.id = res.data[0].portfolios[0].id
            _self.loadData(_self.id)
          }
        }
      })
      .catch(() => {});

      // _self.$api.getCompanies()
      // .then((res) => {
      //   _self.map = res.data
      // })
      // .catch(() => {
      // });
    },
    loadMap(){
       let _self = this
      _self.$api.getPortfoliosMap({
        limit: 999
      })
      .then((res) => {
        if(_self.$at.notEmpty(res.data)){
          this.map = res.data
        }
      })
      .catch(() => {});
    },
    loadData(id){
      let _self = this
      _self.info = []
      _self.$api.portfolioDetail({
        id: id
      })
      .then((res) => {
        this.imageList = res.data.images
        _self.info.push(res.data)
      })
      .catch(() => {});
    },
    read(id){
      this.id = id
      this.loadData(id)
    },
    getNotice(params){
      this.companyInfo = params
    }
  }
};
</script>
<style>
.portfolio-item{
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  padding: 20px 0;
  margin-bottom: 5px;
  cursor: pointer;
}
.active-portfolio-item{
  color: #4AC48F;
}
.content-scroller-wrap{
  height: 970px;
}
.image-viewer-wrapper{
  min-height: 2160px;
  overflow: hidden;
}
.swiper-wrapper{
  margin-top: 50px;
}
.cool-lightbox{
  min-height: 2160px;
}
.cool-lightbox-toolbar .cool-lightbox-toolbar__btn{
  width: 120px;
  height: 120px;
}
.cool-lightbox .cool-lightbox-button{
  width: 160px;
  height: 210px;
}

.cool-lightbox-toolbar__btn[title="Show thumbnails"],
.cool-lightbox-toolbar__btn[title="Play slideshow"]{
  display: none !important;
}
</style>