import * as http from './http'

export default {
  // 城市列表（仅返回关联过公司的城市）
  getCities(param) { return http.get('/monitor/v1/cities', param); },
  // 统计分析 当年至今 数据统计
  getStatisticYear(param) { return http.get('/monitor/v1/statistic/year', param); },
  // 统计分析 （中间部分）
  getStatistic(param) { return http.get('/monitor/v1/statistic', param); },
  // 统计分析 运行参数统计
  getStatisticRun(param) { return http.get('/monitor/v1/statistic/run', param); },
  // 统计分析 雷达
  getStatisticRadar(param) { return http.get('/monitor/v1/statistic/chart-radar-data', param); },
  // 主控台遗体数统计 （左侧）
  getDashboardYts(param) { return http.get('/monitor/v1/dashboard/yts', param); },
  // 主控台统计 （地图右下角）
  getDashboardStatistic(param) { return http.get('/monitor/v1/dashboard/statistic', param); },
  // 设备详情
  getEquipment(id) { return http.get('/monitor/v1/equipment/' + id, {}); },
  getEquipmentCharts(param) { return http.get('/monitor/v1/equipment_chart', param); },
  // 设备列表
  getEquipments(param) { return http.get('/monitor/v1/equipment', param); },
  // 公司列表
  getCompanies(param) { return http.get('/monitor/v1/companies', param); },
  //登录
  login(param) { return http.post('/monitor/v1/auth/login', param); },
  //退出
  logout(param) { return http.post('/monitor/v1/auth/logout', param); },
  // 产业分布-文章详情
  postDetail(param) { return http.get('/monitor/v1/industries/detail', param); },
  // 产业分布-分类列表
  postCategory(param) { return http.get('/monitor/v1/industries/categories', param); },
  // 产业分布-地图
  getIndustriesMap(param) { return http.get('/monitor/v1/industries', param); },
  // 案例分布-地图
  getPortfoliosMap(param) { return http.get('/monitor/v1/portfolios', param); },
  // 产业分布-文章列表
  postList(param) { return http.get('/monitor/v1/industries', param); },
  // 客户案例-文章详情
  portfolioDetail(param) { return http.get('/monitor/v1/portfolios/detail', param); },
  // 客户案例-分类列表
  portfolioCategory(param) { return http.get('/monitor/v1/portfolios/categories', param); },
  // 客户案例-文章列表
  portfolioList(param) { return http.get('/monitor/v1/portfolios', param); },
  // 视频监控
  VideoList(param) { return http.post('/monitor/v1/hikvision/equipment-preview-urls', param); },
  // 预警-设备列表
  getWarnList(param) {return http.get('/monitor/v1/warning/list', param)},
  // 查询产生出错设备的数量
  getWarnListNum(param) {return http.get('/monitor/v1/warning/count', param)},
  // 清除预警设备
  postClearWarnList(param) {return http.post('/monitor/v1/warning/data/clear', param)},
  // 查询所有产生错误和曾经产生错误的设备
  getWarnTotalList(params) {return http.get('/monitor/v1/warning/data/list', params)},
  // 获取预警类型
  getRules(params) {return http.get('/monitor/v1/warning/rule/list', params)}
}
