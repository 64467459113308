<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  mounted(){
      this.getWarnListNum_();
      setInterval(() => {
        this.getWarnListNum_();
      }, 20000);
  },
  methods: {
    getWarnListNum_(){
      this.$api.getWarnListNum().then(r=>{
        this.$store.state.warning_count = r.data.warning_count
      })
    },
  }
};
</script>

<style lang="scss">
@import url('./assets/css/global.scss');
</style>
