import axios from 'axios'
import router from "@/router"
import store from '@/store'
// import { Message } from 'element-ui'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 5 * 1000,
    headers: {
        'Content-Type': 'application/json'
    }
});

let token = ''

// 请求拦截器
instance.interceptors.request.use(config => {
    token = localStorage.getItem('TOKEN')
    config.headers.common['Authorization'] = token
    return config
}, err => {
    return Promise.reject(err)
})

// 响应拦截器
instance.interceptors.response.use(res => {
    return res
}, err => {
    if (err.response.status == 401) {
        localStorage.removeItem('TOKEN')
        if (store.state.isLoginPage === false) {
            router.push('/auth/login')
        }
    }
    return Promise.reject(err)
})

// get请求
export function get(url, params, config = {}) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params,
            ...config
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

// post请求
export function post(url, params, config = {}) {
    return new Promise((resolve, reject) => {
        instance.post(url, params, config)
            .then(res => {
                resolve(res.data)
            }, err => {
                reject(err.data)
            }).catch(err => {
                reject(err.data)
            })
    })
}

// put请求
export function put(url, params, config = {}) {
    return new Promise((resolve, reject) => {
        instance.put(url, params, config)
            .then(res => {
                resolve(res.data)
            }, err => {
                reject(err.data)
            }).catch(err => {
                reject(err.data)
            })
    })
}

// delete请求
export function del(url, params, config = {}) {
    return new Promise((resolve, reject) => {
        instance.delete(url, params, config)
            .then(res => {
                resolve(res.data)
            }, err => {
                reject(err.data)
            }).catch(err => {
                reject(err.data)
            })
    })
}
