<template>
  <div class="monitor">
    <div class="filter-form">
          <el-select v-model="selectCity" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择城市" @change="changeCity" :popper-append-to-body="false">
        <el-option
          v-for="item in city"
          :key="item.id"
          :label="item.title"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="selectCompany" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择公司" :popper-append-to-body="false">
        <el-option
          v-for="item in company"
          :key="item.id"
          :label="item.title"
          :value="item.id"
          :disabled="disabled">
        </el-option>
      </el-select>
      <el-select v-model="selectRule" class="globalSelect smallSelect" popper-class="globalSelectPopper" placeholder="预警类型" :popper-append-to-body="false">
        <el-option
          v-for="item in rules"
          :key="item.id"
          :label="item.title"
          :value="item.id"
          :disabled="disabled">
        </el-option>
      </el-select>
        <el-date-picker
          class="globalPicker"
          v-model="startDate"
          type="date"
          value-format="yyyy-MM-dd"
          popper-class="picker-popper"
          placeholder="--统计开始时间--" :append-to-body="false">
        </el-date-picker>
        <el-date-picker
          class="globalPicker"
          v-model="endDate"
          type="date"
          value-format="yyyy-MM-dd"
          popper-class="picker-popper"
          placeholder="--统计结束时间--" :append-to-body="false">
        </el-date-picker>
        <el-button class="global-btn" type="primary" @click="submit"><img src="@/assets/images/icon/search.png" alt="">筛选</el-button>
        <el-button class="global-btn clear-but"  @click="clear">清除筛选</el-button>
    </div>
    <transition name="el-fade-in-linear">
      <div class="equipment-list-wrap">
        <div v-show="show">
          <div class="equipment-list">
            <div class="equipment-item" v-for="(item, index) in warnlist" v-bind:key="index" @click="goDetail(item.equipment.id)">
              <div  :class="item.deleted_at?'isOpacity':'enOpacity'" class="warning-item">
                  <div class="equipment-first-line flex-between">
                    <div class="equipment-first-line-title">{{item.equipment.title}}</div>
                    <div class="equipment-first-line-create">
                      <div class="y-m-d">{{item.created_at.split(' ')[0]}}</div>
                      <div class="h-m-s">{{item.created_at.split(' ')[1]}}</div>
                    </div>
                  </div>
                  <div class="equipment-error flex-between">
                      <div class="desc-blk"> {{item.warning.title}}: {{item.warning.description}}; </div> 
                      <div v-if="item.deleted_at" class="desc-blk">处理时间：{{item.deleted_at.split(' ')[0]}}</div>
                      <div v-else><div class="clear-warn" @click.stop='postClearWarnList_($event,index)'>
                        <div><i class='el-icon-delete del-icon'></i></div>
                        <div>清除预警</div> </div></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="pagination-wrap" v-if="lastPage>1">
      <el-pagination
        class="global-pagination"
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-count="lastPage"
        >
      </el-pagination>
    </div>
    <!-- <el-dialog :visible.sync="dialogTableVisible" class="video-wrapper" width="3350px" top="100px">
      <el-row :gutter="50">
        <el-col :span="12" v-for="(item, index) in video" :key="index"><div class="video-wrap" :class="index>=2?'video-wrap-top':''"><hls-player :width="1600" :height="900" :autoplay="true" :muted="true" :controls="true" ref="myPlayer" :source="item.url"/></div></el-col>
      </el-row>
    </el-dialog> -->
  </div>
</template>
<script>
import router from "@/router"
var REQUESTLIST;
export default {
  name: "Home",
  data() {
    return {
      city: [],
      startDate: '',
      endDate: '',
      company: [],
      rules: [],
      showDisabled: true,
      disabled: false,
      dialogTableVisible: false,
      video: [],
      status: [
        {
          value: '1',
          label: '运行中'
        },
        {
          value: '0',
          label: '停止中'
        }
      ],
      show: true,
      page: 1,
      lastPage: 1,
      list: [],
      selectCity: '',
      selectCompany: '',
      selectRule: '',
      selectStatus: '',
      loop: true,
      interval: null,
      warnlist: [],
      clearList: []
    }
  },
  beforeDestroy(){
    clearInterval(this.interval)
  },
  mounted() {
    this.page = 1
    this.list = []
    this.loadData();
    this.loadList();
    this.foreachRequest()
    // this.getWarnList_()
    // this.getWarnTotalList_()
    this.clearList = []
    console.log('w', this.warnlist)
  },
  computed: {
    computedStatus() {
      return function(params){
        if(params){
          if(params==1){
            return '自动'
          }else if(params==0){
            return '手动'
          }else{
            return '未知'
          }
        }else{
          return '未知'
        }
      }
    }
  },
  methods: {

    postClearWarnList_(e, index){
      let _self = this
      this.$api.postClearWarnList({
        id: [this.warnlist[index].id]
      }).then(r=>{
        if(r.status === 'success'){
          this.$message({
            type: "success",
            message: r.message
          })
          _self.loadList(_self.page)
          _self.$api.getWarnListNum().then(r=>{
          _self.$store.state.warning_count = r.data.warning_count
          })
        }else{
          this.$message({
            type: "error",
            message: r.message
          })
        }
      })
      
    },
    showVideo(id){
      let _self = this
      _self.showDisabled = false
      _self.$api.VideoList({
        equipment_id: id
      })
      .then((res) => {
        _self.video = res.data
        this.dialogTableVisible = true
        _self.showDisabled = true
      })
      .catch(() => {
        _self.showDisabled = true
      });
    },
    startPlay(){
      this.foreachRequest()
      this.loop=true
    },
    stopPlay(){
      clearInterval(REQUESTLIST)
      this.loop=false
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadList(val);
      if(this.loop){
        clearInterval(REQUESTLIST)
        this.foreachRequest()
      }
    },
    changeCity(e){
      let _self = this
      _self.disabled = true
      _self.$api.getCompanies({
        city_id: e
      })
      .then((res) => {
        _self.company = res.data
        _self.disabled = false
      })
      .catch(() => {
        _self.disabled = false
      });
    },
    goDetail(id){
      router.push('/equipment/detail?id=' + id)
    },
    loadData(){
      let _self = this
      _self.$api.getCities()
      .then((res) => {
        _self.city = res.data
      })
      .catch(() => {});

      _self.$api.getCompanies({
        city_id: this.selectCity || ''
      })
      .then((res) => {
        _self.company = res.data
      })
      .catch(() => {});
      _self.$api.getRules().then(r=>{
        _self.rules = r.data
      })
      // 实时刷新数据
      // this.interval = setInterval(this.getWarnList_, 20000)
    },
    loadList(page){
      let _self = this
      if(_self.show){
        _self.show = false
      }
      _self.$api.getWarnTotalList({
        start_time: _self.startDate || '',
        end_time: _self.endDate || '',
        rule_id: _self.selectRule || '',
        city_id: _self.selectCity || '',
        company_id: _self.selectCompany || '',
        limit: '16',
        page: page || this.page,
      })
      .then((res) => {
        _self.show = true
        _self.warnlist=res.data
        _self.lastPage = res.meta.last_page
      })
      .catch(() => {});
    },
    clear(){
      this.selectCity = ''
      this.selectCompany = ''
      this.selectStatus = ''
      this.startDate = ''
      this.endDate = ''
      this.selectRule = ''
    },
    submit(){
      this.page = 1
      this.loadList();
      if(this.loop){
        clearInterval(REQUESTLIST)
        this.foreachRequest()
      }
    },
    foreachRequest() {
      REQUESTLIST = setInterval(() => {
        if(this.lastPage > this.page){
          this.page = this.page + 1
        }else{
          this.page = 1
        }
        this.loadList(this.page)
      }, 20000);
    },
  },
  destroyed() {
    clearInterval(REQUESTLIST)
  },
}
</script>
<style lang="scss">

.filter-form{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.desc-blk{
  height: 106px;
  line-height: 106px;
}
.clear-warn{
  font-size: 30px;
  padding: 0 50px 0 50px;
  display: flex;
  flex-direction: row;
  height: 106px;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  line-height: 106px;
  background-color: #0F46AA;
  color: #fff;
  font-weight: bolder;
  border-radius: 5px;
  .del-icon{
    font-size: 50px;
    margin-right: 10px;
    ::before{
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.globalSelect.el-select {
  margin-right: 50px;

  .el-input{
    .el-select__caret{
      font-size: 30px;
      color: #222222;
      width: 30px;
    }
  }
  .el-input__suffix{
    right: 30px;
  }
  &.globalSelectSm.el-select .el-input__inner{
    width: 290px;
  }
}

.globalSelectPopper{
  top: 90px !important;
  .el-select-dropdown__item{
    font-size: 30px;
    height: 60px;
    line-height: 60px;
  }
  &.selected{
    color: #0F46AA;
  }
}
.globalSelectPopper.el-popper .popper__arrow::after {
  border-width: 12px;
}
.globalSelectPopper.el-popper[x-placement^=bottom] .popper__arrow::after {
  top: -6px;
  margin-left: -12px;
}
.smallSelect{
  width: 400px;
  .el-input .el-input__inner{
    width: 400px!important;
  }
}
.monitor-clear-btn.global-btn.el-button{
  margin-left: 50px;
}
.monitor-play-btn.global-btn.el-button{
  position: absolute;
  right: 120px;
  top: 0;
}
.equipment-list-wrap{
  min-height: 1716px;
}
.equipment-first-line{
  font-size: 50px;
  font-weight: bold;
  .equipment-first-line-create{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    .y-m-d{
      margin-right: 24px;
      font-size: 30px;
      top: -50%;
      height: 30px;
      transform: translateY(50%);
      display: block;
      text-align: center;
      opacity: .5;
    }
  }
}
.globalPicker.el-date-editor.el-input,
.globalPicker.el-date-editor.el-input__inner{
  width: 474px;
  margin-right: 50px;
}
.globalPicker .el-input__inner{
  height: 90px;
  line-height: 90px;
  border-radius: 0;
  padding: 0 30px;
  font-size: 30px;
  font-weight: bold;
  color: #707070;
}
.equipment-error{
  position: relative;
  height: auto;
  font-weight: normal!important;
  margin-top: 40px;
  box-sizing: border-box;
  font-size: 30px;
  display: block;
  z-index: 10;
  padding: 10px;
  // background-color: #000;

}
.warning-item{
  padding: 50px;
}
.equipment-list{
  display: flex;
  flex-wrap: wrap;
  padding: 50px 95px 0;
  .equipment-item{
    cursor: pointer;
    width: 1560px;
    padding: 0;
    // height: 928px;
    height: auto;
    margin-right: 90px;
    margin-bottom: 65px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    &:nth-child(4n){
      margin-right: 0;
    }
    .equipment-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .equipment-top-left{
        display: flex;
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        flex: 1;
        margin-right: 50px;
        width: 0;
        span{
          max-width: 100%;
        }
        img{
          margin-left: 25px;
          min-width: 70px;
          max-height: 76px;
        }
      }
      .equipment-top-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .now-date{
          font-size: 30px;
          font-weight: bold;
          color: rgba(255,255,255,0.5);
        }
        .now-time{
          font-size: 50px;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
    .equipment-bottom{
      display: flex;
      height: 640px;
      margin-top: 17px;
      padding-top: 10px;
      .equipment-bottom-left{
        min-height: 100%;
        border-right: 2px solid rgba(255,255,255,0.2);
        width: 224px;
        min-width: 224px;
        margin-right: 48px;
        .equipment-status{
          text-align: center;
          margin-bottom: 40px;
          .equipment-status-icon{
            line-height: 0;
            margin-bottom: 14px;
            opacity: .5;
            img{
              max-height: 96px;
            }
          }
          .equipment-status-icon.equipment-status-active{
            opacity: 1;
          }
          .equipment-status-text{
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            opacity: .5;
          }
          .equipment-status-text.equipment-status-active{
            opacity: 1;
          }
        }
        .equipment-status-list{
          .equipment-status-item{
            margin-bottom: 15px;
            font-size: 30px;
            display: flex;
            align-items: center;
            .equipment-status-left{
              color: rgba(255,255,255,0.5);
              margin-right: 20px;
            }
            .equipment-status-right{
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
      .equipment-bottom-middle{
        min-height: 100%;
        width: 355px;
        min-width: 355px;
        padding-right: 65px;
        position: relative;
        .equipment-control{
          .equipment-control-item{
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            font-size: 22px;
            .equipment-control-left{
              color: rgba(255,255,255,0.5);
              margin-right: 40px;
              width: 88px;
              min-width: 88px;
              text-align: right;
            }
            .equipment-control-middle{
              flex: 1;
              width: 0;
              text-align: center;
              color: rgba(255,255,255,0.5);
              font-weight: bold;
            }
            .equipment-control-right{
              color: rgba(255,255,255,0.5);
              margin-left: 30px;
              width: 60px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              font-weight: bold;
              line-height: 1;
            }
          }
          .equipment-control-radio-item{
            .equipment-control-left{
              margin-right: 35px;
            }
            .equipment-control-radio-wrap{
              color: rgba(255,255,255,0.5);
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .equipment-control-radio{
                display: flex;
                align-items: center;
                margin-left: 9px;
                &:first-child{
                  margin-left: 0;
                }
                span{
                  background-color: rgba(255,255,255,0.5);
                  width: 22px;
                  height: 22px;
                  border-radius: 22px;
                  margin-right: 5px;
                  position: relative;
                }
                &-active{
                  span::before{
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    width: 12px;
                    height: 12px;
                    border-radius: 12px;
                    background-color: #4AC48F;
                  }
                }
              }
            }
          }
        }
        .equipment-use{
          position: absolute;
          bottom: 0;
          left: 0;
          min-width: 177px;
          height: 314px;
          padding: 16px 27px 20px 22px;
          background-color: rgba(255,255,255,0.5);
          border-radius: 10px 10px 0 0;
          font-size: 22px;
          font-weight: bold;
          color: #222;
          text-align: center;
          .equipment-use-item-last{
            min-height: 51px;
          }
          .equipment-use-title{
            margin-bottom: 5px;
          }
          .equipment-use-status{
            width: 100%;
            height: 34px;
            background-color: #010147;
            color: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            &-last{
              margin-bottom: 0;
              background-color: #FF0000;
              margin-top: 17px;
            }
          }
        }
      }
      .equipment-bottom-right{
        margin-top: -20px;
        min-height: 100%;
        flex: 1;
        &-top{
          display: flex;
          position: relative;
          .equipment-kq{
            padding-left: 120px;
            padding-top: 18px;
            display: flex;
            margin-right: 14px;
            .equipment-kq-item{
              margin-right: 50px;
              .equipment-kq-icon{
                line-height: 0;
                margin-bottom: 13px;
                &>img{
                  max-height: 140px;
                }
                .equipment-kq-play{
                  position: relative;
                  .equipment-kq-pft{
                    line-height: 0;
                    img{
                      max-height: 140px;
                    }
                  }
                  .equipment-kq-pf{
                    position: absolute;
                    left: 29px;
                    top: 31px;
                    line-height: 0;
                    img{
                      animation: FSXZ 1s linear infinite;
                      max-height: 66px;
                    }
                  }
                }
              }
              .equipment-kq-title{
                font-size: 22px;
                line-height: 1.2;
                color: #fff;
                font-weight: bold;
                text-align: center;
                span{
                  display: block;
                }
              }
            }
          }
          .equipment-inside{
            width: 131px;
            height: 260px;
            padding: 15px;
            background-color: rgba(255,255,255,0.5);
            border-radius: 10px 10px 0 0;
            .equipment-inside-item{
              text-align: center;
              font-size: 22px;
              color: #222222;
              font-weight: bold;
              margin-bottom: 13px;
              &:last-child{
                margin-bottom: 0;
              }
              .equipment-inside-title{
                margin-bottom: 5px;
                line-height: 1.3;
              }
              .equipment-inside-status{
                width: 100%;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: #010147;
                border-radius: 10px;
              }
            }
          }
          .equipment-control-btn-wrap{
            position: absolute;
            right: 0;
            top: 18px;
            width: 110px;
            .equipment-control-btn{
              margin-bottom: 24px;
              width: 100%;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              font-size: 22px;
              line-height: 1;
              font-weight: bold;
            }
          }
        }

        .equipment-bottom-right-bottom{
          position: relative;
          .equipment-info{
            .equipment-pv-and-sv{
              position: absolute;
              top: 0;
              left: 110px;
              width: 610px;
              padding: 16px 86px 5px 75px;
              .equipment-pv-and-sv-item{
                font-size: 22px;
                font-weight: bold;
                color: #222222;
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .equipment-pv{
                  display: flex;
                  align-items: center;
                  margin-right: 35px;
                  .equipment-pv-title{
                    margin-right: 12px;
                  }
                  .equipment-pv-status{
                    width: 128px;
                    height: 34px;
                    line-height: 1;
                    color: #fff;
                    background-color: #010147;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
                .equipment-sv{
                  display: flex;
                  align-items: center;
                  .equipment-sv-title{
                    margin-right: 12px;
                  }
                  .equipment-sv-status{
                    width: 128px;
                    height: 34px;
                    line-height: 1;
                    color: #fff;
                    background-color: #010147;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
            .equipment-img{
              line-height: 0;
              img{
                max-height: 390px;
              }
            }
            .equipment-top-animation,
            .equipment-bottom-animation{
              position: absolute;
              bottom: 15px;
              left: 122px;
              z-index: 1;
              line-height: 0;
              img{
                max-height: 100px;
              }
            }
            .equipment-top-animation{
              bottom: 130px;
            }
            .equipment-ash-status{
              position: absolute;
              left: 0;
              bottom: 55px;
              width: 99px;
              height: 50px;
              background-color: #6B6B6B;
              color: #fff;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              border-radius: 6px;
            }
            .equipment-ash-up,
            .equipment-ash-down{
              position: absolute;
              left: -84px;
              font-weight: bold;
              font-size: 16px;
              color: #fff;
              font-weight: bold;
              width: 64px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .equipment-ash-up{
              bottom: 235px;
              .equipment-ash-up-tilte{
                margin-bottom: 5px;
              }
              .equipment-ash-up-icon{
                width: 40px;
                height: 50px;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E0E0E0;
                border-radius: 6px;
                img{
                  max-height: 24px;
                }
              }
            }
            .equipment-ash-down{
              bottom: 38px;
              .equipment-ash-down-icon{
                width: 40px;
                height: 50px;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E0E0E0;
                border-radius: 6px;
                margin-bottom: 5px;
                img{
                  max-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.pagination-wrap{
  text-align: center;
}
.video-wrapper{
  .el-dialog{
    padding: 50px;
    background: rgba(0, 0, 0, 0.9);
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
  .video-wrap-top{
    margin-top: 50px;
  }
}
.isOpacity{
  opacity: 0.6;
}
.enOpacity{
  background-color: rgba(255, 0,0 ,.2);
  border: 2px solid #FFA200;
}
.flex-between{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.global-btn.clear-but.el-button.el-button--default {
    background-color: #000!important;
color: #fff;
border-color: #000;
margin-left:30px;
}
@keyframes FSXZ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
