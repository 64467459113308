<template>
  <div id="nav">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="transparent"
      text-color="#ffffff"
      active-text-color="#6399FB"
      @select="handleSelect"
      router
    >
      <el-menu-item index="/">主控台</el-menu-item>
      <el-menu-item index="/monitor">实时监控</el-menu-item>
      <el-menu-item index="/warning">
        <span class="warning-badge">{{$store.state.warning_count || 0}}</span>
        <img src="@/assets/images/icon/warning.png" alt="">实时预警
      </el-menu-item>
      <el-menu-item index="/distribution">产业分布</el-menu-item>
      <el-menu-item index="/portfolio">客户案例</el-menu-item>
      <el-menu-item index="/statistics">统计分析</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/",
      num: 0
    };
  },
  mounted() {
    this.activeIndex = this.$route.path;

  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu{
  display: flex;
  align-items: center;
}
.el-menu--horizontal>.el-menu-item{
  padding: 0 0 15px;
  margin: 0 60px;
  font-size: 48px;
  font-weight: bold;
  float: none;
  height: auto;
  line-height: 1;
  border-bottom: 8px solid transparent;
  display: flex;
  align-items: center;
}
.el-menu-item img{
  max-height: 50px;
  margin-right: 40px;
}
.el-menu-item .warning-badge{
  position: absolute;
  left: 40px;
  top: -30px;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  background-color: #FF0000;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 8px solid #6399FB;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: transparent !important;
}
.el-menu.el-menu--horizontal{
  border: 0;
  margin-top: 50px;
}
</style>