<template>
  <div class="home">
    <div class="page-left">
      <div class="home-statistics-item">
        <div class="home-statistics-number"><span class="RC">{{$at.notEmpty(homeStatistics.day) ? homeStatistics.day : '--'}}</span> 具</div>
        <div class="home-statistics-text">昨日处理总数量</div>
      </div>
      <div class="home-statistics-item">
        <div class="home-statistics-number"><span class="RC">{{homeStatistics.yesterdaySingle&&$at.notEmpty(homeStatistics.yesterdaySingle.yts) ? homeStatistics.yesterdaySingle.yts : '--'}}</span> 具</div>
        <div class="home-statistics-text">昨日单台火化机处理最高数量</div>
        <div class="home-statistics-title">{{$at.notEmpty(homeStatistics.yesterdaySingle && homeStatistics.yesterdaySingle.equipment&&homeStatistics.yesterdaySingle.equipment.title) ? homeStatistics.yesterdaySingle.equipment.title : '--'}}</div>
      </div>
      <div class="home-statistics-item">
        <div class="home-statistics-number"><span class="RC">{{$at.notEmpty(homeStatistics.month) ? homeStatistics.month : '--'}}</span> 具</div>
        <div class="home-statistics-text">当月处理总数量</div>
      </div>
      <div class="home-statistics-item home-statistics-item-last">
        <div class="home-statistics-number"><span class="RC">{{$at.notEmpty(homeStatistics.year) ? homeStatistics.year : '--'}}</span> 具</div>
        <div class="home-statistics-text">{{currentYear}}年处理总数量</div>
      </div>
      <div class="home-statistics-img">
        <v-chart :options="options" v-if="!dataEmpty" />
        <div class="home-statistics-text">近12个月数据汇总</div>
      </div>
    </div>
    <div class="page-middle">
      <DashboardMap :map="map" />
    </div>
    <div class="home-statistics-count">
      <div class="home-statistics-count-item">
        <div class="statistics-count-text">服务殡仪馆</div>
        <div class="statistics-count-number RC">{{$at.notEmpty(homeStatisticsRight.countCompanies) ? homeStatisticsRight.countCompanies : '--'}}</div>
        <div class="statistics-count-text">家</div>
      </div>
      <div class="home-statistics-count-item home-statistics-count-item-middle">
        <div class="statistics-count-text">生产线运行</div>
        <div class="statistics-count-number RC">{{$at.notEmpty(homeStatisticsRight.countEquipment) ? homeStatisticsRight.countEquipment : '--'}}</div>
        <div class="statistics-count-text">(拣灰{{$at.notEmpty(homeStatisticsRight.ashtype) ? homeStatisticsRight.ashtype.jianhuilu : '--'}}/平板{{$at.notEmpty(homeStatisticsRight.ashtype) ? homeStatisticsRight.ashtype.pingbanlu : '--'}})台</div>
      </div>
      <div class="home-statistics-count-item">
        <div class="statistics-count-text">运行中</div>
        <div class="statistics-count-number RC">{{$at.notEmpty(homeStatisticsRight.countRuningEquipment) ? homeStatisticsRight.countRuningEquipment : '--'}}</div>
        <div class="statistics-count-text">台</div>
      </div>
    </div>
    <div class="page-right">
      <transition name="el-fade-in-linear">
        <div class="home-equipment-list">
          <div v-show="show">
          <div class="home-equipment-item" v-for="(item, index) in list" v-bind:key="index">
            <div class="home-equipment-top">
              <div class="home-equipment-status">
                <img v-if="item.data.running_state===1" src="@/assets/images/icon/fire02.png" alt="">
                <img v-else src="@/assets/images/icon/fire01.png" alt="">
              </div>
              <div class="home-equipment-info">
                <div class="home-equipment-company clamp">{{item.company.title}}</div>
                <div class="home-equipment-title clamp">{{item.title}}</div>
              </div>
            </div>
            <div class="home-equipment-bottom">
              <div class="home-equipment-params">
                <div class="home-equipment-params-list">
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">状态</div>
                    <div class="home-equipment-params-right" v-if="item.data.running_state===1">运行中</div>
                    <div class="home-equipment-params-right" v-else>设备已离线</div>
                  </div>
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">主燃</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_zrscswd) ? item.data.f_zrscswd : '--'}}&deg;C</div>
                  </div>
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">二燃</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_ecrscswd) ? item.data.f_ecrscswd : '--'}}&deg;C</div>
                  </div>
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">烟道</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_ydcswd) ? item.data.f_ydcswd : '--'}}&deg;C</div>
                  </div>
                </div>
                <div class="home-equipment-params-list">
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">功率</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_zrhydx) ? item.data.f_zrhydx : '--'}}%</div>
                  </div>
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">功率</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_erhydx) ? item.data.f_erhydx : '--'}}%</div>
                  </div>
                  <div class="home-equipment-params-item">
                    <div class="home-equipment-params-left">炉压</div>
                    <div class="home-equipment-params-right">{{$at.notEmpty(item.data.f_dnyl) ? item.data.f_dnyl : '--'}}Pa</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </transition>
      <el-pagination
        v-if="lastPage>1"
        class="global-pagination"
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-count="lastPage"
        >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DashboardMap from "@/components/Map/EchartsMap.vue";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
var REQUESTDATA;
var REQUESTLIST;
var WARNLISTCOUNT;
export default {
  name: "Home",
  components: {
    DashboardMap,
    "v-chart": ECharts
  },
  data() {
    return {
      map: [],
      options:{
        grid: {
          x: 0,
          y: 50,
          x2: 0,
          y2: 100
        },
        interval: null,
        series: {
          type: "bar",
          data: [],
          color: "#fff",
          barWidth: 10,
          label: { show: true, position: "top",
            textStyle: {
                color: "#fff",
                fontSize: 32
            }
          },
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine:{
            show: true,
            lineStyle: {
              width: 2,
              color: '#fff'
            }
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 32
            }
          }
        },
        yAxis: {
          show:false,
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 32
            }
          }
        }
      },
      show: true,
      homeStatistics: {},
      homeStatisticsRight: {},
      chartData: {
        columns: ['month', 'count_yts'],
        rows: []
      },
      dataEmpty: false,
      page: 1,
      lastPage: 1,
      list: [],
      currentYear: ''
    }
  },
  mounted() {
    this.interval = null
    this.page = 1
    this.list = []
    this.loadData();
    this.loadList();
    this.foreachData();
    this.foreachRequest()
    this.currentYear = dayjs().format("YYYY");
  },
  methods: {
    handleCurrentChange(val) {
      clearInterval(REQUESTLIST)
      this.page = val
      this.loadList(val);
      this.foreachRequest()
    },

    loadData(){
      let _self = this
      _self.$api.getDashboardYts()
      .then((res) => {
        _self.homeStatistics = res.data
        let chartData = res.data.monthsDetail
        if(!chartData || chartData.length==0){
          _self.dataEmpty = true
        }else{
          _self.dataEmpty = false
          let xAxis = []
          let series = []
          for(let i = 0; i<chartData.length; i++){
            xAxis.push(chartData[i].month)
            series.push(chartData[i].count_yts)
          }
          _self.options.xAxis.data = xAxis
          _self.options.series.data = series
        }
      })
      .catch(() => {});

      _self.$api.getDashboardStatistic()
      .then((res) => {
        _self.homeStatisticsRight = res.data
      })
      .catch(() => {});

      _self.$api.getCompanies()
      .then((res) => {
        _self.map = res.data
      })
      .catch(() => {
      });
      // this.getWarnListNum_()
    },
    loadList(page){
      let _self = this
      if(_self.show){
        _self.show = false
      }
      _self.$api.getEquipments({
        with_company: 1,
        with_data: 1,
        page: page || _self.page,
        limit: 4
      })
      .then((res) => {
        _self.show = true
        _self.list = res.data
        _self.lastPage = res.meta.last_page
      })
      .catch(() => {});
    },
    foreachData() {
      REQUESTDATA = setInterval(() => {
        this.loadData();
      }, 20000);
    },
    foreachRequest() {
      REQUESTLIST = setInterval(() => {
        if(this.lastPage > this.page){
          this.page = this.page + 1
        }else{
          this.page = 1
        }
        this.loadList(this.page)
      }, 20000);
    },
  },
  destroyed() {
    clearInterval(REQUESTDATA)
    clearInterval(REQUESTLIST)
    clearInterval(WARNLISTCOUNT)
  },
};
</script>
<style lang="scss">
.home .page-left{
  width: 1440px;
  padding: 380px 200px 50px 200px;
}
.home .page-right{
  width: 1440px;
  padding: 270px 200px 50px 200px;
}
.home-statistics{
  &-item{
    padding-bottom: 60px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 25px;
    &.home-statistics-item-last{
      border: 0;
      padding-bottom: 70px;
      margin-bottom: 0;
    }
    .home-statistics-number{
      font-size: 40px;
      span{
        font-size: 120px;
      }
    }
    .home-statistics-text{
      font-size: 40px;
      color: #fff;
    }
    .home-statistics-title{
      font-weight: bold;
      font-size: 40px;
      color: #fff;
    }
  }
}
.home-statistics-text{
  font-size: 40px;
  color: #fff;
}
.home-equipment-list{
  min-height: 1646px;
  .home-equipment-item{
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 40px;
    .home-equipment-top{
      display: flex;
      align-items: center;
      margin-bottom: 27px;
      .home-equipment-status{
        line-height: 0;
        width: 90px;
        min-width: 90px;
        margin-right: 20px;
        img{
          max-height: 90px;
        }
      }
      .home-equipment-info{
        flex: 1;
        width: 0;
        color: #fff;
        .home-equipment-company{
          font-size: 30px;
          color: rgba(255, 255, 255, 0.6);
        }
        .home-equipment-title{
          font-size: 40px;
          font-weight: bold;
        }
      }
    }
    .home-equipment-bottom{
      .home-equipment-params{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .home-equipment-params-list{
          .home-equipment-params-item{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            font-size: 30px;
            line-height: 1.2;
            color: #fff;
            .home-equipment-params-left{
              width: 80px;
              margin-right: 10px;
            }
            .home-equipment-params-right{
              font-weight: bold;
              width: 150px;
            }
          }
        }
      }
    }
  }
}
.global-pagination.el-pagination.is-background .btn-prev,
.global-pagination.el-pagination.is-background .btn-next,
.global-pagination.el-pagination.is-background .el-pager li{
  font-size: 22px;
  color: #707070;
  font-weight: bold;
  min-width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 0;
  margin: 0 10px;
  padding: 0 5px;
  background-color: #FFFFFF;
}
.global-pagination.el-pagination.is-background .btn-next .el-icon,
.global-pagination.el-pagination.is-background .btn-prev .el-icon{
  font-size: 22px;
}
.global-pagination.el-pagination.is-background .btn-next.disabled,
.global-pagination.el-pagination.is-background .btn-next:disabled,
.global-pagination.el-pagination.is-background .btn-prev.disabled,
.global-pagination.el-pagination.is-background .btn-prev:disabled,
.global-pagination.el-pagination.is-background .el-pager li.disabled {
    color: #fff;
    background-color: #cccccc;
}
.global-pagination.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #0F46AA;
}
.global-pagination.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #0F46AA;
  color: #fff;
}
.home-statistics-count{
  position: absolute;
  right: 1500px;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-item{
    margin: 0 20px;
    width: 270px;
    text-align: center;
    .statistics-count-number{
      font-size: 160px;
    }
    .statistics-count-text{
      font-size: 40px;
      color: #fff;
    }
  }
  .home-statistics-count-item-middle{
    width: 470px;
  }
}
.home-statistics-img{
  width: 100%;
  height: 480px;
  .echarts {
    width: 100%;
    height: 100%;
  }
}
</style>