import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from "../views/NotFound.vue"
import AuthLayout from "../views/layout/AuthLayout.vue"
import MainLayout from "../views/layout/MainLayout.vue"
import DetailLayout from "../views/layout/DetailLayout.vue"
import Login from "../views/Login.vue"
import Home from "../views/Home.vue"
import Monitor from "../views/Monitor.vue"
import Warning from "../views/Warning.vue"
import Distribution from "../views/Distribution.vue"
import Portfolio from "../views/Portfolio.vue"
import Statistics from "../views/Statistics.vue"
import Detail from "../views/Detail.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainLayout', // 主布局
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home', // 首页 主控台
        component: Home
      },
      {
        path: 'monitor',
        name: 'Monitor', // 实时监控
        component: Monitor
      },
      {
        path: 'warning',
        name: 'Warning', // 实时预警
        component: Warning
      },
      {
        path: 'distribution',
        name: 'Distribution', // 产业分布
        component: Distribution
      },
      {
        path: 'portfolio',
        name: 'Portfolio', // 客户案例
        component: Portfolio
      },
      {
        path: 'statistics',
        name: 'Statistics', // 统计分析
        component: Statistics
      }
    ]
  },
  {
    path: '/equipment',
    name: 'DetailLayout', // 设备详情页面布局
    component: DetailLayout,
    children: [
      {
        path: 'detail',
        name: 'Detail', // 设备详情
        component: Detail,
      },
    ]
  },
  {
    path: '/auth',
    name: 'AuthLayout', // 认证页面布局
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login', // 登录
        component: Login,
      },
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
