<template>
  <div class="echarts-map">
    <v-chart :options="mapOptions" @click="click" />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataset";
import "echarts/lib/component/geo";
import chinaMap from "./china.json"; // 也可通过接口加载，减小体积
ECharts.registerMap("china", chinaMap);

export default {
  name: "EchartsMap",
  components: {
    "v-chart": ECharts,
  },
  props: ['map'],
  data() {
    return {
      mapOptions: {
        dataset: {
          dimensions: ["id", "title", "longitude", "latitude"],
          source: [],
        },
        toolbox: {
          show: false,
          feature: {
            restore: {},
          },
        },
        geo: {
          map: "china",
          roam: false, // 鼠标缩放
          zoom: 1.2,
          itemStyle: {
            // 地图区域的多边形 图形样式
            areaColor: "#71A3FF",
            borderColor: "#3B5077",
            borderWidth: 1,
          },
          emphasis: {
            // 高亮状态下的多边形和标签样式
            label: {
              color: "#fff", // 文字颜色
              fontSize: 20, // 标记的大小
            },
            itemStyle: {
              areaColor: "#1F66E8", // 区域颜色
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 0, // 为 0 时不绘制该图形
                }
              },
              label: {
                show: false // 隐藏文字
              }
            }
          ]
        },
        series: [
          {
            name: "companies",
            type: "scatter", // 散点（气泡）图
            coordinateSystem: "geo", // 使用地理坐标系
            symbolSize: 22, // 标记的大小
            encode: {
              lng: "longitude",
              lat: "latitude",
            },
            label: {
              normal: {
                show: false
              },
              emphasis: { // 对应的鼠标悬浮效果
                show: true,
                position: "right",
                padding: [20, 20, 16, 20],
                borderRadius:[4, 4, 4, 4],
                backgroundColor: '#0F46AA',
                formatter: "{@title}",
                textStyle: {
                  color: "#ffffff",
                  fontSize: 30, // 标记的大小
                  lineHeight: 30
                }
              }
            },
            itemStyle: {
              color: "#C00000", // 标记颜色
              borderWidth: 3,//边框宽度
              borderColor: '#fff',//边框颜色
              // shadowBlur: 5,
              // shadowColor: "#fff",
            },
          },
        ],
      },
      oldValue: []
    };
  },
  watch: {
    map(newValue, oldValue){
      this.mapOptions.dataset.source = newValue
      this.oldValue = oldValue
    }
  },
  methods: {
    click(params) {
      console.log(params)
      // if(this.$at.notEmpty(params.value)){
      //   this.$emit('noticeParent', params.value)
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts-map {
  height: 1600px;
  .echarts {
    width: 100%;
    height: 100%;
  }
}
</style>