<template>
  <div class="monitor">
    <div class="filter-form">
      <el-select v-model="selectCity" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择城市" @change="changeCity" :popper-append-to-body="false">
        <el-option
          v-for="item in city"
          :key="item.id"
          :label="item.title"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="selectCompany" class="globalSelect" popper-class="globalSelectPopper" placeholder="请选择公司" :popper-append-to-body="false">
        <el-option
          v-for="item in company"
          :key="item.id"
          :label="item.title"
          :value="item.id"
          :disabled="disabled">
        </el-option>
      </el-select>
      <el-select v-model="selectStatus" class="globalSelect globalSelectSm" popper-class="globalSelectPopper" placeholder="请选择状态" :popper-append-to-body="false">
        <el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button class="global-btn" type="primary" @click="submit"><img src="@/assets/images/icon/search.png" alt="">筛选</el-button>
      <el-button class="global-btn monitor-clear-btn" type="info" @click="clear">清除筛选</el-button>
      <el-button class="global-btn monitor-play-btn" type="success" v-if="loop" @click="stopPlay"><img src="@/assets/images/icon/play.png" alt="">停止循环</el-button>
      <el-button class="global-btn monitor-play-btn" type="success" v-else @click="startPlay"><img src="@/assets/images/icon/play.png" alt="">循环播放</el-button>
    </div>
    <transition name="el-fade-in-linear">
      <div class="equipment-list-wrap">
        <div v-show="show">
          <div class="equipment-list">
            <div class="equipment-item" v-for="(item, index) in list" v-bind:key="index" @click="goDetail(item.id)">
              <div class="equipment-top">
                <div class="equipment-top-left">
                  <span class="clamp">{{item.title}}</span>
                  <div v-if="item.has_camera">
                    <img v-if="showDisabled" @click.stop="showVideo(item.id)" src="@/assets/images/icon/equipment.png" alt="">
                  </div>
                </div>
                <div class="equipment-top-right"><div class="now-date">{{item.data.last_updated.date}}</div><div class="now-time R">{{item.data.last_updated.time}}</div></div>
              </div>
              <div class="equipment-bottom">
                <div class="equipment-bottom-left">
                  <div class="equipment-status">
                    <span v-if="item.data.running_state===1">
                      <div class="equipment-status-icon equipment-status-active"><img src="@/assets/images/icon/fire02.png" alt=""></div>
                      <div class="equipment-status-text equipment-status-active">运行中</div>
                    </span>
                    <span v-else>
                      <div class="equipment-status-icon"><img src="@/assets/images/icon/fire01.png" alt=""></div>
                      <div class="equipment-status-text">设备已离线</div>
                    </span>
                  </div>
                  <div class="equipment-status-list">
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">主燃</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_zrscswd) ? item.data.f_zrscswd : '--'}}&deg;C</div>
                    </div>
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">功率</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_zrhydx) ? item.data.f_zrhydx : '--'}}%</div>
                    </div>
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">二燃</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_ecrscswd) ? item.data.f_ecrscswd : '--'}}&deg;C</div>
                    </div>
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">功率</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_erhydx) ? item.data.f_erhydx : '--'}}%</div>
                    </div>
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">烟道</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_ydcswd) ? item.data.f_ydcswd : '--'}}&deg;C</div>
                    </div>
                    <div class="equipment-status-item">
                      <div class="equipment-status-left">炉压</div>
                      <div class="equipment-status-right">{{$at.notEmpty(item.data.f_dnyl) ? item.data.f_dnyl : '--'}}Pa</div>
                    </div>
                  </div>
                </div>
                <div class="equipment-bottom-middle">
                  <div class="equipment-control">
                    <div class="equipment-control-item">
                      <div class="equipment-control-left">顶嘴进风</div>
                      <div class="equipment-control-middle">{{$at.notEmpty(item.data.f_dfkql) ? item.data.f_dfkql : '--'}}%</div>
                      <div class="equipment-control-right">{{computedStatus(item.data.f_dbff)}}</div>
                    </div>
                    <div class="equipment-control-item">
                      <div class="equipment-control-left">侧部进风</div>
                      <div class="equipment-control-middle">{{$at.notEmpty(item.data.f_cfkql) ? item.data.f_cfkql : '--'}}%</div>
                      <div class="equipment-control-right">{{computedStatus(item.data.f_cbff)}}</div>
                    </div>
                    <div class="equipment-control-item">
                      <div class="equipment-control-left">二次进风</div>
                      <div class="equipment-control-middle">{{$at.notEmpty(item.data.f_erfkql) ? item.data.f_erfkql : '--'}}%</div>
                      <div class="equipment-control-right">{{computedStatus(item.data.f_erff)}}</div>
                    </div>
                    <div class="equipment-control-item">
                      <div class="equipment-control-left">主燃烧</div>
                      <div class="equipment-control-middle">{{$at.notEmpty(item.data.f_zrhydx) ? item.data.f_zrhydx : '--'}}%</div>
                      <div class="equipment-control-right">{{computedStatus(item.data.f_zrzt)}}</div>
                    </div>
                    <div class="equipment-control-item">
                      <div class="equipment-control-left">二次燃烧</div>
                      <div class="equipment-control-middle">{{$at.notEmpty(item.data.f_erhydx) ? item.data.f_erhydx : '--'}}%</div>
                      <div class="equipment-control-right">{{computedStatus(item.data.f_erzt)}}</div>
                    </div>
                    <div class="equipment-control-item equipment-control-radio-item">
                      <div class="equipment-control-left">焚烧模式</div>
                      <div class="equipment-control-radio-wrap">
                        <div class="equipment-control-radio" :class="item.data.f_fsms && item.data.f_fsms==1 ? 'equipment-control-radio-active': ''"><span></span>低</div>
                        <div class="equipment-control-radio" :class="item.data.f_fsms && item.data.f_fsms==2 ? 'equipment-control-radio-active': ''"><span></span>中</div>
                        <div class="equipment-control-radio" :class="item.data.f_fsms && item.data.f_fsms==3 ? 'equipment-control-radio-active': ''"><span></span>高</div>
                      </div>
                    </div>
                  </div>
                  <div class="equipment-use">
                    <div class="equipment-use-item">
                      <div class="equipment-use-title">当前流量</div>
                      <div class="equipment-use-status">{{$at.notEmpty(item.data.f_dqll) ? item.data.f_dqll : '--'}}{{item.energytype ? item.energytype : ''}}</div>
                    </div>
                    <div class="equipment-use-item">
                      <div class="equipment-use-title">本次使用量</div>
                      <div class="equipment-use-status">{{$at.notEmpty(item.data.f_djythn) ? item.data.f_djythn : '--'}}{{item.energytype ? item.energytype : ''}}</div>
                    </div>
                    <div class="equipment-use-item">
                      <div class="equipment-use-title">燃烧累积量</div>
                      <div class="equipment-use-status">{{$at.notEmpty(item.data.f_lshn) ? item.data.f_lshn : '--'}}{{item.energytype ? item.energytype : ''}}</div>
                    </div>
                    <div class="equipment-use-item equipment-use-item-last">
                      <div class="equipment-use-status equipment-use-status-last" v-if="item.energytype&&item.energytype=='L'">油泵</div>
                    </div>
                  </div>
                </div>
                <div class="equipment-bottom-right">
                  <div class="equipment-bottom-right-top">
                    <div class="equipment-kq">
                      <div class="equipment-kq-item">
                        <div class="equipment-kq-icon">
                          <div class="equipment-kq-play" v-if="item.data.f_gyfjyxpl && Number(item.data.f_gyfjyxpl) > 0">
                            <div class="equipment-kq-pft"><img src="@/assets/images/icon/paifeng01.png" alt=""></div>
                            <div class="equipment-kq-pf"><img src="@/assets/images/icon/paifeng02.png" alt=""></div>
                          </div>
                          <img v-else src="@/assets/images/icon/paifeng.png" alt="">
                        </div>
                        <div class="equipment-kq-title"><span>助燃空气</span><span>{{$at.notEmpty(item.data.f_gyfjyxpl) ? item.data.f_gyfjyxpl : '--'}}Hz</span></div>
                      </div>
                      <div class="equipment-kq-item">
                        <div class="equipment-kq-icon">
                          <div class="equipment-kq-play" v-if="item.data.f_slfjyxpl && Number(item.data.f_slfjyxpl) > 0">
                            <div class="equipment-kq-pft"><img src="@/assets/images/icon/paifeng01.png" alt=""></div>
                            <div class="equipment-kq-pf"><img src="@/assets/images/icon/paifeng02.png" alt=""></div>
                          </div>
                          <img v-else src="@/assets/images/icon/paifeng.png" alt="">
                        </div>
                        <div class="equipment-kq-title"><span>排风</span><span>{{$at.notEmpty(item.data.f_slfjyxpl) ? item.data.f_slfjyxpl : '--'}}Hz</span></div>
                      </div>
                    </div>
                    <div class="equipment-inside">
                      <div class="equipment-inside-item">
                        <div class="equipment-inside-title">烟道温度</div>
                        <div class="equipment-inside-status">{{$at.notEmpty(item.data.f_ydcswd) ? item.data.f_ydcswd : '--'}}&deg;C</div>
                      </div>
                      <div class="equipment-inside-item">
                        <div class="equipment-inside-title">含氧量</div>
                        <div class="equipment-inside-status">{{$at.notEmpty(item.data.f_ydhyl) ? item.data.f_ydhyl : '--'}}%</div>
                      </div>
                      <div class="equipment-inside-item">
                        <div class="equipment-inside-title">炉内压力</div>
                        <div class="equipment-inside-status">{{$at.notEmpty(item.data.f_dnyl) ? item.data.f_dnyl : '--'}}Pa</div>
                      </div>
                    </div>
                    <!-- 待处理 -->
                    <div class="equipment-control-btn-wrap">
                      <div class="equipment-control-btn bg-white gray" :class="item.data.f_ksyr && item.data.f_ksyr==1 ? 'bg-yellow-im white-im': ''">开始预热</div>
                      <div class="equipment-control-btn bg-white gray" :class="item.data.f_kshh && item.data.f_kshh==1 ? 'bg-yellow-im white-im': ''">开始火化</div>
                      <div class="equipment-control-btn bg-white gray" :class="item.data.f_hhwc && item.data.f_hhwc==1 ? 'bg-yellow-im white-im': ''">火化完成</div>
                      <div class="equipment-control-btn bg-white gray" :class="item.data.f_jstj && item.data.f_jstj==1 ? 'bg-yellow-im white-im': ''">结束停机</div>
                      <div class="equipment-control-btn bg-white gray" :class="item.data.f_jjtj && item.data.f_jjtj==1 ? 'bg-yellow-im white-im': ''">紧急停机</div>
                    </div>
                  </div>
                  <div class="equipment-bottom-right-bottom">
                    <div class="equipment-info">
                      <div class="equipment-pv-and-sv">
                        <div class="equipment-pv-and-sv-item">
                          <div class="equipment-pv">
                            <div class="equipment-pv-title">主燃烧PV</div>
                            <div class="equipment-pv-status">{{item.data.f_zrscswd ? item.data.f_zrscswd : '--'}}&deg;C</div>
                          </div>
                          <div class="equipment-sv">
                            <div class="equipment-sv-title">SV</div>
                            <div class="equipment-sv-status">{{item.data.f_zrsszwd ? item.data.f_zrsszwd : '--'}}&deg;C</div>
                          </div>
                        </div>
                        <div class="equipment-pv-and-sv-item">
                          <div class="equipment-pv">
                            <div class="equipment-pv-title">二燃烧PV</div>
                            <div class="equipment-pv-status">{{item.data.f_ecrscswd ? item.data.f_ecrscswd : '--'}}&deg;C</div>
                          </div>
                          <div class="equipment-sv">
                            <div class="equipment-sv-title">SV</div>
                            <div class="equipment-sv-status">{{item.data.f_ecrsszwd ? item.data.f_ecrsszwd : '--'}}&deg;C</div>
                          </div>
                        </div>
                      </div>
                      <div class="equipment-img"><img src="@/assets/images/icon/jiqi.png" alt=""></div>
                      <div class="equipment-top-animation equipmentZR" v-if="item.data.f_zrszh&&item.data.f_zrszh==1&&item.data.f_zrhydx">
                        <img v-if="item.data.f_zrhydx&&Number(item.data.f_zrhydx)<=20" src="@/assets/images/icon/fire20.gif" alt="">
                        <img v-else-if="item.data.f_zrhydx&&Number(item.data.f_zrhydx)<=40" src="@/assets/images/icon/fire40.gif" alt="">
                        <img v-else-if="item.data.f_zrhydx&&Number(item.data.f_zrhydx)<=60" src="@/assets/images/icon/fire60.gif" alt="">
                        <img v-else-if="item.data.f_zrhydx&&Number(item.data.f_zrhydx)<=80" src="@/assets/images/icon/fire80.gif" alt="">
                        <img v-else-if="item.data.f_zrhydx&&Number(item.data.f_zrhydx)<=100" src="@/assets/images/icon/fire100.gif" alt="">
                      </div>
                      <div class="equipment-bottom-animation equipmentER" v-if="item.data.f_ecrszh&&item.data.f_ecrszh==1&&item.data.f_erhydx">
                        <img v-if="item.data.f_erhydx&&Number(item.data.f_erhydx)<=20" src="@/assets/images/icon/fire20.gif" alt="">
                        <img v-else-if="item.data.f_erhydx&&Number(item.data.f_erhydx)<=40" src="@/assets/images/icon/fire40.gif" alt="">
                        <img v-else-if="item.data.f_erhydx&&Number(item.data.f_erhydx)<=60" src="@/assets/images/icon/fire60.gif" alt="">
                        <img v-else-if="item.data.f_erhydx&&Number(item.data.f_erhydx)<=80" src="@/assets/images/icon/fire80.gif" alt="">
                        <img v-else-if="item.data.f_erhydx&&Number(item.data.f_erhydx)<=100" src="@/assets/images/icon/fire100.gif" alt="">
                      </div>
                      <div class="equipment-ash-up">
                        <div class="equipment-ash-up-tilte">灰门上升</div>
                        <div class="equipment-ash-up-icon" :class="item.data.f_hlmdk && item.data.f_hlmdk==1 ? 'bg-green-im': ''"><img src="@/assets/images/icon/up.png" alt=""></div>
                      </div>
                      <div class="equipment-ash-down">
                        <div class="equipment-ash-down-icon" :class="item.data.f_hlmgb && item.data.f_hlmgb==1 ? 'bg-green-im': ''"><img src="@/assets/images/icon/down.png" alt=""></div>
                        <div class="equipment-ash-down-tilte">灰门下降</div>
                      </div>
                      <div class="equipment-ash-status" :class="item.data.f_ghlq && item.data.f_ghlq==1 ? 'bg-green-im': ''">骨灰冷却</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="pagination-wrap" v-if="lastPage>1">
      <el-pagination
        class="global-pagination"
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-count="lastPage"
        >
      </el-pagination>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" class="video-wrapper" width="3350px" top="100px">
      <el-row :gutter="50" v-if="dialogTableVisible">
        <el-col :span="12" v-for="(item, index) in video" :key="index"><div class="video-wrap" :class="index>=2?'video-wrap-top':''"><hls-player :width="1600" :height="900" :autoplay="true" :muted="true" :controls="true" ref="myPlayer" :source="item.url"/></div></el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import router from "@/router"
var REQUESTLIST;
export default {
  name: "Home",
  data() {
    return {
      city: [],
      company: [],
      showDisabled: true,
      disabled: false,
      dialogTableVisible: false,
      video: [],
      status: [
        {
          value: '1',
          label: '运行中'
        },
        {
          value: '0',
          label: '停止中'
        }
      ],
      show: true,
      page: 1,
      lastPage: 1,
      list: [],
      selectCity: '',
      selectCompany: '',
      selectStatus: '',
      loop: true
    }
  },
  mounted() {
    this.page = 1
    this.list = []
    this.loadData();
    this.loadList();
    this.foreachRequest()
  },
  computed: {
    computedStatus() {
      return function(params){
        if(params){
          if(params==1){
            return '自动'
          }else if(params==0){
            return '手动'
          }else{
            return '未知'
          }
        }else{
          return '未知'
        }
      }
    }
  },
  methods: {
    showVideo(id){
      let _self = this
      _self.showDisabled = false
      _self.$api.VideoList({
        equipment_id: id
      })
      .then((res) => {
        _self.video = res.data
        this.dialogTableVisible = true
        _self.showDisabled = true
      })
      .catch(() => {
        _self.showDisabled = true
      });
    },
    startPlay(){
      this.foreachRequest()
      this.loop=true
    },
    stopPlay(){
      clearInterval(REQUESTLIST)
      this.loop=false
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadList(val);
      if(this.loop){
        clearInterval(REQUESTLIST)
        this.foreachRequest()
      }
    },
    changeCity(e){
      let _self = this
      _self.disabled = true
      _self.$api.getCompanies({
        city_id: e
      })
      .then((res) => {
        _self.company = res.data
        _self.disabled = false
      })
      .catch(() => {
        _self.disabled = false
      });
    },
    goDetail(id){
      router.push('/equipment/detail?id=' + id)
    },
    loadData(){
      let _self = this
      _self.$api.getCities()
      .then((res) => {
        _self.city = res.data
      })
      .catch(() => {});

      _self.$api.getCompanies({
        city_id: this.selectCity || ''
      })
      .then((res) => {
        _self.company = res.data
      })
      .catch(() => {});
    },
    loadList(page){
      let _self = this
      if(_self.show){
        _self.show = false
      }
      _self.$api.getEquipments({
        with_company: 0,
        with_data: 1,
        city_id: _self.selectCity || '',
        company_id: _self.selectCompany || '',
        running: _self.selectStatus || '',
        page: page || this.page,
      })
      .then((res) => {
        _self.show = true
        _self.list=res.data
        _self.lastPage = res.meta.last_page
      })
      .catch(() => {});
    },
    clear(){
      this.selectCity = ''
      this.selectCompany = ''
      this.selectStatus = ''
    },
    submit(){
      this.page = 1
      this.loadList();
      if(this.loop){
        clearInterval(REQUESTLIST)
        this.foreachRequest()
      }
    },
    foreachRequest() {
      REQUESTLIST = setInterval(() => {
        if(this.lastPage > this.page){
          this.page = this.page + 1
        }else{
          this.page = 1
        }
        this.loadList(this.page)
      }, 20000);
    },
  },
  destroyed() {
    clearInterval(REQUESTLIST)
  },
}
</script>
<style lang="scss">
.filter-form{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globalSelect.el-select {
  margin-right: 50px;
  .el-input__inner{
    border-radius: 0;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    font-weight: bold;
    color: #707070;
    padding: 0 80px 0 30px;
    width: 720px;
  }
  .el-input{
    .el-select__caret{
      font-size: 30px;
      color: #222222;
      width: 30px;
    }
  }
  .el-input__suffix{
    right: 30px;
  }
  &.globalSelectSm.el-select .el-input__inner{
    width: 290px;
  }
}
.globalSelectPopper{
  top: 90px !important;
  .el-select-dropdown__item{
    font-size: 30px;
    height: 60px;
    line-height: 60px;
  }
  &.selected{
    color: #0F46AA;
  }
}
.globalSelectPopper.el-popper .popper__arrow::after {
  border-width: 12px;
}
.globalSelectPopper.el-popper[x-placement^=bottom] .popper__arrow::after {
  top: -6px;
  margin-left: -12px;
}
.monitor-clear-btn.global-btn.el-button{
  margin-left: 50px;
}
.monitor-play-btn.global-btn.el-button{
  position: absolute;
  right: 120px;
  top: 0;
}
.equipment-list-wrap{
  min-height: 1716px;
}
.equipment-list{
  display: flex;
  flex-wrap: wrap;
  padding: 50px 95px 0;
  .equipment-item{
    cursor: pointer;
    width: 1560px;
    height: 768px;
    margin-right: 90px;
    margin-bottom: 65px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 35px 47px 0 38px;
    overflow: hidden;
    &:nth-child(4n){
      margin-right: 0;
    }
    .equipment-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .equipment-top-left{
        display: flex;
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        flex: 1;
        margin-right: 50px;
        width: 0;
        span{
          max-width: 100%;
        }
        img{
          margin-left: 25px;
          min-width: 70px;
          max-height: 76px;
        }
      }
      .equipment-top-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .now-date{
          font-size: 30px;
          font-weight: bold;
          color: rgba(255,255,255,0.5);
        }
        .now-time{
          font-size: 50px;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
    .equipment-bottom{
      display: flex;
      height: 640px;
      margin-top: 17px;
      padding-top: 10px;
      .equipment-bottom-left{
        min-height: 100%;
        border-right: 2px solid rgba(255,255,255,0.2);
        width: 224px;
        min-width: 224px;
        margin-right: 48px;
        .equipment-status{
          text-align: center;
          margin-bottom: 40px;
          .equipment-status-icon{
            line-height: 0;
            margin-bottom: 14px;
            opacity: .5;
            img{
              max-height: 96px;
            }
          }
          .equipment-status-icon.equipment-status-active{
            opacity: 1;
          }
          .equipment-status-text{
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            opacity: .5;
          }
          .equipment-status-text.equipment-status-active{
            opacity: 1;
          }
        }
        .equipment-status-list{
          .equipment-status-item{
            margin-bottom: 15px;
            font-size: 30px;
            display: flex;
            align-items: center;
            .equipment-status-left{
              color: rgba(255,255,255,0.5);
              margin-right: 20px;
            }
            .equipment-status-right{
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
      .equipment-bottom-middle{
        min-height: 100%;
        width: 355px;
        min-width: 355px;
        padding-right: 65px;
        position: relative;
        .equipment-control{
          .equipment-control-item{
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            font-size: 22px;
            .equipment-control-left{
              color: rgba(255,255,255,0.5);
              margin-right: 40px;
              width: 88px;
              min-width: 88px;
              text-align: right;
            }
            .equipment-control-middle{
              flex: 1;
              width: 0;
              text-align: center;
              color: rgba(255,255,255,0.5);
              font-weight: bold;
            }
            .equipment-control-right{
              color: rgba(255,255,255,0.5);
              margin-left: 30px;
              width: 60px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              font-weight: bold;
              line-height: 1;
            }
          }
          .equipment-control-radio-item{
            .equipment-control-left{
              margin-right: 35px;
            }
            .equipment-control-radio-wrap{
              color: rgba(255,255,255,0.5);
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .equipment-control-radio{
                display: flex;
                align-items: center;
                margin-left: 9px;
                &:first-child{
                  margin-left: 0;
                }
                span{
                  background-color: rgba(255,255,255,0.5);
                  width: 22px;
                  height: 22px;
                  border-radius: 22px;
                  margin-right: 5px;
                  position: relative;
                }
                &-active{
                  span::before{
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    width: 12px;
                    height: 12px;
                    border-radius: 12px;
                    background-color: #4AC48F;
                  }
                }
              }
            }
          }
        }
        .equipment-use{
          position: absolute;
          bottom: 0;
          left: 0;
          min-width: 177px;
          height: 314px;
          padding: 16px 27px 20px 22px;
          background-color: rgba(255,255,255,0.5);
          border-radius: 10px 10px 0 0;
          font-size: 22px;
          font-weight: bold;
          color: #222;
          text-align: center;
          .equipment-use-item-last{
            min-height: 51px;
          }
          .equipment-use-title{
            margin-bottom: 5px;
          }
          .equipment-use-status{
            width: 100%;
            height: 34px;
            background-color: #010147;
            color: #fff;
            border-radius: 10px;
            margin-bottom: 10px;
            &-last{
              margin-bottom: 0;
              background-color: #FF0000;
              margin-top: 17px;
            }
          }
        }
      }
      .equipment-bottom-right{
        margin-top: -20px;
        min-height: 100%;
        flex: 1;
        &-top{
          display: flex;
          position: relative;
          .equipment-kq{
            padding-left: 120px;
            padding-top: 18px;
            display: flex;
            margin-right: 14px;
            .equipment-kq-item{
              margin-right: 50px;
              .equipment-kq-icon{
                line-height: 0;
                margin-bottom: 13px;
                &>img{
                  max-height: 140px;
                }
                .equipment-kq-play{
                  position: relative;
                  .equipment-kq-pft{
                    line-height: 0;
                    img{
                      max-height: 140px;
                    }
                  }
                  .equipment-kq-pf{
                    position: absolute;
                    left: 29px;
                    top: 31px;
                    line-height: 0;
                    img{
                      animation: FSXZ 1s linear infinite;
                      max-height: 66px;
                    }
                  }
                }
              }
              .equipment-kq-title{
                font-size: 22px;
                line-height: 1.2;
                color: #fff;
                font-weight: bold;
                text-align: center;
                span{
                  display: block;
                }
              }
            }
          }
          .equipment-inside{
            width: 131px;
            height: 260px;
            padding: 15px;
            background-color: rgba(255,255,255,0.5);
            border-radius: 10px 10px 0 0;
            .equipment-inside-item{
              text-align: center;
              font-size: 22px;
              color: #222222;
              font-weight: bold;
              margin-bottom: 13px;
              &:last-child{
                margin-bottom: 0;
              }
              .equipment-inside-title{
                margin-bottom: 5px;
                line-height: 1.3;
              }
              .equipment-inside-status{
                width: 100%;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: #010147;
                border-radius: 10px;
              }
            }
          }
          .equipment-control-btn-wrap{
            position: absolute;
            right: 0;
            top: 18px;
            width: 110px;
            .equipment-control-btn{
              margin-bottom: 24px;
              width: 100%;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              font-size: 22px;
              line-height: 1;
              font-weight: bold;
            }
          }
        }
        .equipment-bottom-right-bottom{
          position: relative;
          .equipment-info{
            .equipment-pv-and-sv{
              position: absolute;
              top: 0;
              left: 110px;
              width: 610px;
              padding: 16px 86px 5px 75px;
              .equipment-pv-and-sv-item{
                font-size: 22px;
                font-weight: bold;
                color: #222222;
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .equipment-pv{
                  display: flex;
                  align-items: center;
                  margin-right: 35px;
                  .equipment-pv-title{
                    margin-right: 12px;
                  }
                  .equipment-pv-status{
                    width: 128px;
                    height: 34px;
                    line-height: 1;
                    color: #fff;
                    background-color: #010147;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
                .equipment-sv{
                  display: flex;
                  align-items: center;
                  .equipment-sv-title{
                    margin-right: 12px;
                  }
                  .equipment-sv-status{
                    width: 128px;
                    height: 34px;
                    line-height: 1;
                    color: #fff;
                    background-color: #010147;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
            .equipment-img{
              line-height: 0;
              img{
                max-height: 390px;
              }
            }
            .equipment-top-animation,
            .equipment-bottom-animation{
              position: absolute;
              bottom: 15px;
              left: 122px;
              z-index: 1;
              line-height: 0;
              img{
                max-height: 100px;
              }
            }
            .equipment-top-animation{
              bottom: 130px;
            }
            .equipment-ash-status{
              position: absolute;
              left: 0;
              bottom: 55px;
              width: 99px;
              height: 50px;
              background-color: #6B6B6B;
              color: #fff;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              border-radius: 6px;
            }
            .equipment-ash-up,
            .equipment-ash-down{
              position: absolute;
              left: -84px;
              font-weight: bold;
              font-size: 16px;
              color: #fff;
              font-weight: bold;
              width: 64px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .equipment-ash-up{
              bottom: 235px;
              .equipment-ash-up-tilte{
                margin-bottom: 5px;
              }
              .equipment-ash-up-icon{
                width: 40px;
                height: 50px;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E0E0E0;
                border-radius: 6px;
                img{
                  max-height: 24px;
                }
              }
            }
            .equipment-ash-down{
              bottom: 38px;
              .equipment-ash-down-icon{
                width: 40px;
                height: 50px;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E0E0E0;
                border-radius: 6px;
                margin-bottom: 5px;
                img{
                  max-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.pagination-wrap{
  text-align: center;
}
.video-wrapper{
  .el-dialog{
    padding: 50px;
    background: rgba(0, 0, 0, 0.9);
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
  .video-wrap-top{
    margin-top: 50px;
  }
}
.disabled-icon{
  opacity: 0.6;
}
@keyframes FSXZ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
