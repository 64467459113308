/**
 * 验证是否为数字
 */
export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * 数字前补零
 */
export function prefixZero(num, length) {
  return (Array(length).join("0") + num).slice(-length);
}

/**
 * 验证是否为空
 */
export function notEmpty(params) {
  var verification = Object.prototype.toString.call(params);
  if(verification == '[object Undefined]' || verification == '[object Null]'){
    return false
  }else if(verification == '[object Boolean]'){
    if(params){
      return true
    }else{
      return false
    }
  }else if(verification == '[object Number]' || verification == '[object Date]'){
    return true
  }else if(verification == '[object String]'){
    if(params == "") {
      return false;
    } else {
      return true;
    }
  }else if(verification == '[object Object]'){
    if(Object.keys(params) == 0){
      return false
    }else{
      return true
    }
  }else if(verification == '[object Array]'){
    if(params.length == 0){
      return false
    }else{
      return true
    }
  }else if(verification == '[object Function]' || verification == '[object RegExp]' || verification == '[object Error]' || verification == '[object Arguments]'){
    console.log('特殊类型')
    return false
  }
}
